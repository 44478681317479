import React, { useCallback, useState } from 'react';
import { gql } from '@apollo/client';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  Box,
  Button,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  Input,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { FaSearch, FaChevronDown } from 'react-icons/fa';
import { format, utcToZonedTime } from 'date-fns-tz';

import userLookupIcon from '../../../Images/event-manage-v2/user-lookup.png';

import { Card } from './Card';
import { ApplyCouponModal } from './Modals/ApplyCouponModal';
import { AssignCaptainModal } from './Modals/AssignCaptainModal';
import { CompetitionsModal } from './Modals/EditCompetitionsModal';
import { MarkAsPaidModal } from './Modals/MarkAsPaidModal';
import { ChangeRegOptionModal } from './Modals/ChangeRegOptionModal';
import { ChangeSchoolModal } from './Modals/ChangeSchoolModal';
import { ApproveRegModal } from './Modals/ApproveRegModal';
import { CancelRegistrationModal } from './Modals/CancelRegistrationModal';

const DISPLAY_DATE_FORMAT = 'MMM d yyyy hh:mm aa';
const USER_LONG_TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

const FETCH_EVENTS_USERS = gql`
query fetchUserInfo($eventId: Int!, $code: String, $email: String) {
  fetchUserDetail(code: $code, email: $email) {
    firstname
    lastname
    email
    phone
    code
    profileImageUrl
    dateOfBirth
    gender
    id
  }
  fetchEventsUsersByUser(eventId: $eventId, code: $code, email: $email) {
    id
    status
    schoolId
    fetchTeam {
      id
      code
      school {
        id
        name
      }
    }
    fetchSchoolUser {
      status
      fetchUserAttachment {
        id
        verificationFileUrl
      }
      fetchSchool {
        id
        name
      }
    }
    fetchRole {
      id
      title
    }
    fetchMembers {
      id
      fetchEventCompetition {
        id
        title
        status
      }
    }
    fetchOrder {
      id
      amount
      status
    }
    registrationOptionId
  }
  fetchCanceledEventsUsersByUser(eventId: $eventId, code: $code, email: $email) {
    id
    status
    createdAt
    canceledAt
    canceledBy {
      id
      firstname
      lastname
      email
    }
    fetchTeam {
      id
      code
      school {
        id
        name
      }
    }
    fetchSchoolUser {
      status
      fetchUserAttachment {
        id
        verificationFileUrl
      }
      fetchSchool {
        id
        name
      }
    }
    fetchRole {
      id
      title
    }
    fetchOrder {
      id
      amount
      status
    }
    registrationOptionId
  }
}
`;

export const QuickUserLookup = ({ eventId }) => {
  const [input, setInput] = useState('');
  const toast = useToast();
  const [fetchEventsUsers, { data, loading }] = useLazyQuery(FETCH_EVENTS_USERS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.fetchEventsUsersByUser.length === 0
        && data.fetchCanceledEventsUsersByUser.length === 0) {
        toast({
          title: `Could not find user ${input} in event.`,
          status: 'error',
          isClosable: true,
          position: 'top',
        });
      }
    },
    onError: (err) => {
      toast({
        title: err.graphQLErrors[0]?.message ?? 'An unknown error occurred. Please try again later.',
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    },
  });

  const lookupUser = useCallback(async (evt, input) => {
    evt.preventDefault();
    const variables = { eventId };
    if (input.includes('@')) {
      // it's an email
      variables.email = input;
    } else {
      variables.code = input;
    }

    fetchEventsUsers({ variables });
  }, [fetchEventsUsers, eventId]);

  const hasEventsUsers = !!data && (
    data.fetchEventsUsersByUser.length > 0
    || data.fetchCanceledEventsUsersByUser.length > 0
  );

  return (
    <Card title="Quick User Lookup" imgSrc={userLookupIcon}>
      <Box width="100%">
        <form onSubmit={(e) => lookupUser(e, input)} width="100%">
          <HStack width="100%">
            <Input placeholder="Enter MIST ID or email address" value={input} onChange={(e) => setInput(e.target.value)} />
            <IconButton icon={<FaSearch />} aria-label="search" type="submit" isLoading={loading} />
          </HStack>
        </form>
        {hasEventsUsers && (
          <UserInfo
            eventId={eventId}
            user={data.fetchUserDetail}
            eventsUsers={data.fetchEventsUsersByUser}
            canceledEventsUsers={data.fetchCanceledEventsUsersByUser}
          />
        )}
      </Box>
    </Card>
  );
};

const SchoolUserStatus = ({ status }) => {
  switch (status) {
    case 'active': return <Text as="span" color="blue.500" fontWeight="bold">Approved</Text>;
    case 'pending': return <Text as="span" color="orange.500">Pending</Text>;
    default: return <Text>{status}</Text>;
  }
};

const SchoolVerificationModal = ({ isOpen, onClose, verificationFileUrl }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>School Verification</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Image src={verificationFileUrl} />
      </ModalBody>

      <ModalFooter>
        <Button colorScheme="blue" mr={3} onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

const SchoolInfo = ({
  code, status, role, team, verificationAttachments, isCaptain,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [visibleVerificationFileUrl, setVisibleVerificationFileUrl] = useState();
  const showVerification = (verificationFileUrl) => {
    setVisibleVerificationFileUrl(verificationFileUrl);
    onOpen();
  };

  return (
    <Box borderStartColor="grey.200" borderStartWidth={2} paddingInlineStart={2}>
      <Text color="gray.500">{code}</Text>
      <Text>
        {team.school.name}
        {isCaptain ? ' (Captain)' : ''}
      </Text>
      <Box>
        <Box marginBlockStart={0}>
          <SchoolUserStatus status={status} />
          {' '}
          {role.title}
        </Box>
        {verificationAttachments.map((attachment) => (
          <Button
            key={attachment.id}
            variant="link"
            colorScheme="blue"
            fontWeight="light"
            onClick={() => showVerification(attachment.verificationFileUrl)}
          >
            View School Verification
          </Button>
        ))}
      </Box>
      <SchoolVerificationModal
        isOpen={isOpen}
        onClose={onClose}
        verificationFileUrl={visibleVerificationFileUrl}
      />
    </Box>
  );
};

const PaymentStatus = ({ status }) => {
  switch (status) {
    // actively in the event
    case 'complete': return <Text as="span" color="green.500" fontWeight="bold">Paid</Text>;
    case 'pending': return <Text as="span" color="orange.500">Pending</Text>;

    // canceled
    case 'paid': return <Text as="span" color="red.500">Paid</Text>;
    case 'unpaid': return <Text as="span">Unpaid</Text>;
    default: return <Text>{status}</Text>;
  }
};

const PaymentInfo = ({ order }) => {
  const amount = order.amount / 100;
  return (
    <Box>
      <PaymentStatus status={order.status} />
      {' amount of $'}
      {amount}
    </Box>
  );
};

const CompetitionsInfo = ({
  eventId, userId, eventUserId, members,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        variant="link"
        colorScheme="blue"
        fontWeight="light"
        onClick={() => onOpen()}
      >
        View/Edit Competitions
      </Button>
      <CompetitionsModal
        isOpen={isOpen}
        onClose={onClose}
        eventId={eventId}
        userId={userId}
        eventUserId={eventUserId}
        members={members}
      />
    </>
  );
};

const EventUserInfo = ({
  eventId, user, eventUser, isCaptain,
}) => {
  let { code } = user;
  if (eventUser.fetchTeam) {
    code = `${eventUser.fetchTeam.code}-${user.code}`;
  }

  return (
    <>
      {eventUser.fetchSchoolUser && (
        <SchoolInfo
          code={code}
          status={eventUser.status}
          team={eventUser.fetchTeam}
          role={eventUser.fetchRole}
          verificationAttachments={eventUser.fetchSchoolUser.fetchUserAttachment}
          isCaptain={isCaptain}
        />
      )}
      <PaymentInfo order={eventUser.fetchOrder} />
      {eventUser.fetchMembers && (
        <CompetitionsInfo
          eventId={eventId}
          userId={user.id}
          eventUserId={eventUser.id}
          members={eventUser.fetchMembers}
        />
      )}
    </>
  );
};

const CancelledEventUserInfo = ({
  user, eventUser,
}) => {
  let { code } = user;
  if (eventUser.fetchTeam) {
    code = `${eventUser.fetchTeam.code}-${user.code}`;
  }

  let cancelText = 'Canceled';
  if (eventUser.canceledAt && eventUser.canceledBy) {
    const canceledAt = format(utcToZonedTime(eventUser.canceledAt, USER_LONG_TZ), DISPLAY_DATE_FORMAT);
    cancelText += ` by ${eventUser.canceledBy.firstname} ${eventUser.canceledBy.lastname} on ${canceledAt}`;
  }

  return (
    <>
      <Text as="span" color="red.500">{cancelText}</Text>
      {eventUser.fetchTeam && (
        <Box borderStartColor="grey.200" borderStartWidth={2} paddingInlineStart={2}>
          <Text color="gray.500">{code}</Text>
          <Text>{eventUser.fetchRole.title}</Text>
          <Text>
            {eventUser.fetchTeam.school.name}
          </Text>
        </Box>
      )}
      {eventUser.fetchOrder && (
        <PaymentInfo
          order={{
            ...eventUser.fetchOrder,
            status: eventUser.fetchOrder.status === 'complete' ? 'paid' : 'unpaid',
          }}
        />
      )}
    </>
  );
};

const ApproveRegButton = ({
  eventId,
  user,
  eventUser,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const disabled = !eventUser.fetchSchoolUser;

  return (
    <>
      <MenuItem onClick={onOpen} isDisabled={disabled}>Approve Registration</MenuItem>
      {isOpen && (
        <ApproveRegModal
          isOpen={isOpen}
          onClose={onClose}
          eventId={eventId}
          user={user}
          eventUser={eventUser}
        />
      )}
    </>
  );
};

const AssignAsCaptainButton = ({
  eventId,
  schoolId,
  eventUserId,
  userName,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <MenuItem onClick={onOpen}>Promote to Captain</MenuItem>
      <AssignCaptainModal
        isOpen={isOpen}
        onClose={onClose}
        eventId={eventId}
        schoolId={schoolId}
        eventUserId={eventUserId}
        userName={userName}
      />
    </>
  );
};

const ChangeSchoolButton = ({
  disabled, eventId, eventUserId, currentSchoolId, userName,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <MenuItem isDisabled={disabled} onClick={onOpen}>Change School</MenuItem>
      {isOpen && (
        <ChangeSchoolModal
          isOpen={isOpen}
          onClose={onClose}
          eventId={eventId}
          eventUserId={eventUserId}
          currentSchoolId={currentSchoolId}
          userName={userName}
        />
      )}
    </>
  );
};

const ChangeRegOptionButton = ({
  disabled, eventId, eventUserId, currentRegistrationOptionId, userName,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <MenuItem isDisabled={disabled} onClick={onOpen}> Change Registration Type</MenuItem>
      {isOpen && (
        <ChangeRegOptionModal
          isOpen={isOpen}
          onClose={onClose}
          eventId={eventId}
          eventUserId={eventUserId}
          currentRegistrationOptionId={currentRegistrationOptionId}
          userName={userName}
        />
      )}
    </>
  );
};

const MarkAsPaidButton = ({
  disabled, eventId, userId, userName,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <MenuItem isDisabled={disabled} onClick={onOpen}>Mark As Paid</MenuItem>
      <MarkAsPaidModal
        isOpen={isOpen}
        onClose={onClose}
        eventId={eventId}
        userId={userId}
        userName={userName}
      />
    </>
  );
};

const ApplyCouponButton = ({
  disabled, eventId, userId, orderId, registrationOptionId, userName,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <MenuItem isDisabled={disabled} onClick={onOpen}>Apply Coupon</MenuItem>
      {isOpen && (
        <ApplyCouponModal
          isOpen={isOpen}
          onClose={onClose}
          eventId={eventId}
          userId={userId}
          orderId={orderId}
          registrationOptionId={registrationOptionId}
          userName={userName}
        />
      )}
    </>
  );
};

const CancelRegistrationButton = ({
  eventUserId,
  userName,
  hasPaid,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <MenuItem onClick={onOpen} color="red.600">Cancel Registration</MenuItem>
      <CancelRegistrationModal
        isOpen={isOpen}
        onClose={onClose}
        eventUserId={eventUserId}
        userName={userName}
        hasPaid={hasPaid}
        refetchQueries={['fetchUserInfo']}
      />
    </>
  );
};

const CAPTAIN_ROLE_ID = '6';
const UserInfo = ({ eventId, user, eventsUsers, canceledEventsUsers }) => {
  const fullname = `${user.firstname} ${user.lastname}`;

  const isCaptainForSchools = eventsUsers
    .filter((eu) => eu.fetchRole.id === CAPTAIN_ROLE_ID)
    .map((eu) => eu.schoolId);

  const nonCaptainEventUsers = eventsUsers
    .filter((eu) => eu.fetchRole.id !== CAPTAIN_ROLE_ID);

  const firstCanceledEventUser = canceledEventsUsers
    .filter((eu) => eu.fetchRole.id !== CAPTAIN_ROLE_ID)[0];

  return (
    <HStack alignItems="start" width="100%" marginBlockStart={4}>
      <Image height={12} width={12} borderRadius="100%" src={user.profileImageUrl} />
      <VStack alignItems="start" width="100%">
        <HStack flexGrow={1} justifyContent="space-between" width="100%">
          <Text>{fullname}</Text>
          {eventsUsers.length > 0 && (
            <Menu>
              <MenuButton as={Button} colorScheme="gray" rightIcon={<FaChevronDown />}>
                Edit Details
              </MenuButton>
              <MenuList>
                {eventsUsers.length === 1 && (
                  <>
                      {eventsUsers[0].status === 'pending' && nonCaptainEventUsers.length === 1 && (
                        <>
                          <ApproveRegButton eventId={eventId} user={user} eventUser={nonCaptainEventUsers[0]} />
                          <MenuDivider />
                        </>
                      )}
                      {isCaptainForSchools.length === 0 && nonCaptainEventUsers.length === 1 && nonCaptainEventUsers[0]?.status === 'active' && (
                      <AssignAsCaptainButton
                        eventId={eventId}
                        schoolId={nonCaptainEventUsers[0]?.schoolId}
                        eventUserId={nonCaptainEventUsers[0]?.id}
                        userName={fullname}
                      />
                      )}
                    <ChangeSchoolButton
                      disabled={!nonCaptainEventUsers || nonCaptainEventUsers.length !== 1}
                      eventId={eventId}
                      eventUserId={nonCaptainEventUsers[0]?.id}
                      currentSchoolId={nonCaptainEventUsers[0]?.schoolId}
                      userName={fullname}
                    />
                    <ChangeRegOptionButton
                      disabled={!nonCaptainEventUsers || nonCaptainEventUsers.length !== 1}
                      eventId={eventId}
                      eventUserId={nonCaptainEventUsers[0]?.id}
                      currentRegistrationOptionId={nonCaptainEventUsers[0]?.registrationOptionId}
                      userName={fullname}
                    />
                    <MenuDivider />
                  </>
                )}
                <MarkAsPaidButton
                  disabled={!nonCaptainEventUsers[0]?.fetchOrder || nonCaptainEventUsers[0].fetchOrder.status === 'complete'}
                  eventId={eventId}
                  userId={user.id}
                  userName={fullname}
                />
                <ApplyCouponButton
                  disabled={!nonCaptainEventUsers[0]?.fetchOrder || nonCaptainEventUsers[0].fetchOrder.status === 'complete'}
                  eventId={eventId}
                  userId={user.id}
                  orderId={nonCaptainEventUsers[0].fetchOrder.id}
                  registrationOptionId={nonCaptainEventUsers[0].registrationOptionId}
                  userName={fullname}
                />
                <MenuDivider />
                <CancelRegistrationButton
                  eventUserId={nonCaptainEventUsers[0]?.id}
                  userName={fullname}
                  hasPaid={nonCaptainEventUsers[0]?.fetchOrder.status === 'complete'}
                />
              </MenuList>
            </Menu>
          )}
        </HStack>
        {nonCaptainEventUsers.map((eu) => (
          <EventUserInfo
            key={eu.id}
            eventId={eventId}
            user={user}
            eventUser={eu}
            isCaptain={isCaptainForSchools.includes(eu.schoolId)}
          />
        ))}
        {nonCaptainEventUsers.length === 0 && firstCanceledEventUser && (
          <CancelledEventUserInfo
            user={user}
            eventUser={firstCanceledEventUser}
          />
        )}
      </VStack>

    </HStack>
  );
};
