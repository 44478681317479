import React, { useEffect, useState, useMemo } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useMutation, useLazyQuery, useQuery } from '@apollo/react-hooks';
import queryString from 'query-string';
import {
  getOr, get, first, isEmpty, last,
} from 'lodash/fp';
import styled from 'styled-components';
import {
  FETCH_EVENT,
  FETCH_ORDERS_BY_EVENT,
  FETCH_EVENTS_USERS_BY_USERS, UNPAID_COUNT, PENDING_REQUEST_COUNT, USER_INVITATION_COUNT,
  FETCH_EVENT_USERS_BY_SCHOOL,
  FETCH_EVENT_TEAMS,
  PENDING_TERM_AND_CONDITION_COUNT,
  FETCH_TEAM_MEMBERS,
  FETCH_EVENTS_USER_SCHOOLS,
  MANAGE_MY_COMPETITIONS,
  FETCH_EVENTS_USERS_COUNT_BY_COACH,
  FETCH_EVENTS_SCHOOL_TEAM,
} from '../../GraphQL/Queries';
import {
  CANCEL_EVENT, CANCEL_REGISTRATION_OPTION, TEAM_FINALIZE, JOIN_EVENT_BY_INVITATION,
  WITHDRAW_TEAM,
} from '../../GraphQL/Mutations';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useUpdateEventsByUserCache from '../../Hooks/useUpdateEventsByUserCache';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import GenericAlert from '../../Components/GenericAlert';
import useCurrentUser from '../../Hooks/userCurrentUser';
import Loader from '../../Components/Loader';
import ListItem from '../../Components/ListItem/Index';
import {
  FontBold,
  TitleC,
} from '../../Components/Auth/Layout';
import { Input } from '../../Components/Form';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import useEventsUserPermissionChecker from '../../Hooks/useEventsUserPermissionChecker';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import CancelRegistrationOption from './CancelRegistrationOption';
import CancelEvent from './CancelEvent';
import ReassignCaptainBeforeCancelRegistration from './ReassignCaptainBeforeCancelRegistration';
import CancelItem from '../../Components/CancelItem/Index';
import RIGHT_ARROW from '../../Images/icons/right-color-arrow.png';
import Permissions from '../../Constants/PermissionConstrants';
import useUpdateActiveEventsCache from '../../Hooks/useUpdateActiveEventsCache';
import useFilterArray from '../../Hooks/useFilterArray';
import PopUpModal from '../../Components/PopUpModal';
import useUpdateEventsUsersByUsersCache from '../../Hooks/useUpdateEventsUsersByUsersCache';
import useNotEqualFilterArray from '../../Hooks/useNotEqualFilterArray';
import useUpdateOrdersCache from '../../Hooks/useUpdateOrdersCache';
import useUpdateUserPermissionCache from '../../Hooks/useUpdateUserPermissionCache';
import useFormParams from '../../Hooks/useFormParams';
import { isRoundFinalized } from '../Scores/CompetitionScores';

const ListButton = styled.button`
  background: none;
  border: none;
  test-align: left;
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  padding-block: 0;
  padding-inline: 24px;
  width: 100%;
`;

const TEAMID = styled.div`
  text-align:center;
  color:black;
  font-size:15px;
  padding-top:12px;
  font-weight:bold;
  font-family:Arial;
`;

const CompRow = ({ eventCompetition, round, eventId }) => {
  const name = eventCompetition.rounds.length === 1 ? eventCompetition.title : round.name;
  return (
    <ListItem
      title={name}
      redirectLink={`/events/${eventId}/judging/${eventCompetition.id}/rounds/${round.id}`}
      checkPermission
    />
  );
};

const CompsToJudge = ({ eventId, judgeEventsUser }) => {
  const hasFinalizedRounds = judgeEventsUser?.competitionsToJudge
    .flatMap((ec) => ec.rounds)
    .filter((r) => isRoundFinalized(r))
    .length > 0;
  return (
    <>
      <FontBold className="pt-3">
        <p>Judging</p>
      </FontBold>
      {judgeEventsUser?.competitionsToJudge.flatMap((ec) => ec.rounds
        .filter((r) => !isRoundFinalized(r))
        .map((r) => <CompRow eventCompetition={ec} round={r} eventId={eventId} />)
      )}
      {hasFinalizedRounds && (
        <details>
          <summary>See completed rounds:</summary>
          {judgeEventsUser?.competitionsToJudge.flatMap((ec) => ec.rounds
            .filter((r) => isRoundFinalized(r))
            .map((r) => <CompRow eventCompetition={ec} round={r} eventId={eventId} />)
          )}
        </details>
      )}
    </>
  );
};

const EventManage = () => {
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);

  const location = useLocation();
  const param = queryString.parse(location.search);
  const filterArray = useFilterArray();
  const notEqualFilterArray = useNotEqualFilterArray();
  const [totalMembers, setTotalMembers] = useState([]);
  const [modalRegistration, setModalRegistration] = useState(false);
  const [modalReassignCaptain, setModalReassignCaptain] = useState(false);
  const [modalEvent, setModalEvent] = useState(false);
  const checkPermission = useUserPermissionChecker(currentUser);
  const updateCache = useUpdateEventsByUserCache(getOr(0, 'id', currentUser));
  const updateEventCache = useUpdateActiveEventsCache();
  const [paymentStatus, setPaymentStatus] = useState('');
  const { id } = useParams();
  const history = useHistory();
  const updateUserPermissionCache = useUpdateUserPermissionCache();
  const extractError = useGraphQLErrorExtractor();
  const [finalizeTeamModal, setFinalizeTeamModal] = useState(false);
  const [withdrawCoachModal, setWithdrawCoachModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [schoolsUsers, setSchoolsUsers] = useState([]);
  const updateCacheEventsUsersByUsers = useUpdateEventsUsersByUsersCache(id, getOr(0, 'id', currentUser));
  const [fetchTeamMembers, { data: dataTeamMembers }] = useLazyQuery(
    FETCH_TEAM_MEMBERS,
    {
      onCompleted: (data) => {
        const filteredMembers = notEqualFilterArray(data.viewMyTeam, 'fetchRole.title', 'Captain');
        setTotalMembers(filteredMembers.length);
    },
    fetchPolicy: 'network-only',
    }
  );

  const teamMembers = getOr([], 'viewMyTeam', dataTeamMembers);


  const [fetchEventsUsersByUsers, { data: dataEventsUser }] = useLazyQuery(
    FETCH_EVENTS_USERS_BY_USERS,
  );

  const eventsUsers = getOr([], 'fetchEventsUsersByUser', dataEventsUser);

  const eventsUser = useMemo(() => first(filterArray((getOr([], 'fetchEventsUsersByUser', dataEventsUser)), 'fetchRole.title', 'Captain')), [dataEventsUser, filterArray]);
  const judgeEventsUser = useMemo(() => first(filterArray((getOr([], 'fetchEventsUsersByUser', dataEventsUser)), 'fetchRole.title', 'Judge')), [dataEventsUser, filterArray]);

  const checkPermissionofEventsUser = useEventsUserPermissionChecker(eventsUser);

  const [fetchOrdersByEvent, { data: dataOrder }] = useLazyQuery(
    FETCH_ORDERS_BY_EVENT,
  );

  const order = first(getOr([], 'fetchOrdersByEvent', dataOrder));

  const [fetchEventUsersBySchool, { data: dataEventUsersBySchool }] = useLazyQuery(
    FETCH_EVENT_USERS_BY_SCHOOL,
  );

  const eventUsersBySchool = getOr([], 'fetchEventUsersBySchool', dataEventUsersBySchool);

  const [fetchEventTeams, { data: teamsData }] = useLazyQuery(
    FETCH_EVENT_TEAMS,
  );

  const eventTeams = getOr([], 'eventTeams', teamsData);

  useEffect(() => {
    if (order && order.status === 'pending') {
      setPaymentStatus('Unpaid');
    } else {
      setPaymentStatus('Paid');
    }
  }, [order]);

  const { params, onChange, setParams } = useFormParams({
    schoolId: '',
  });

  const [fetchEventsUserSchools, {
    data: dataEventsUserSchools,
    loading: eventUsersLoading,
  }] = useLazyQuery(FETCH_EVENTS_USER_SCHOOLS, {
    onCompleted: (data) => {
      if (data.fetchEventsUsersByUser.length > 0) {
        setParams(first(data.fetchEventsUsersByUser));
      }
    },
  });

  const eventsUserSchools = getOr([], 'fetchEventsUsersByUser', dataEventsUserSchools);

  useEffect(() => {
    if (currentUser && id) {
      fetchEventsUserSchools({
        variables: {
          eventId: parseInt(id, 10),
          userId: parseInt(currentUser.id, 10),
          schoolId: null,
        },
      });
    }
  }, [currentUser, fetchEventsUserSchools, id]);

  useEffect(() => {
    if (!isEmpty(eventsUserSchools)) {
      const unique = eventsUserSchools
        .map((e) => e.schoolId)
        .map((e, i, final) => final.indexOf(e) === i && i)
        .filter((e) => eventsUserSchools[e]).map((e) => eventsUserSchools[e]);
      setSchoolsUsers(unique);
    }
  }, [eventsUserSchools]);

  const [joinEventByInvitation, { loading: joinEventByInvitationLoading }] = useMutation(
    JOIN_EVENT_BY_INVITATION,
    {
      onCompleted: (data5) => {
        if (data5.joinEventByInvitation.eventsUser) {
          updateCacheEventsUsersByUsers(data5.joinEventByInvitation.eventsUser, 'update');
          currentUser.fetchRole = data5.joinEventByInvitation.eventsUser.fetchRole;
          updateUserPermissionCache(currentUser);
          history.push(`/event-manage/${parseInt(id, 10)}`);

          fetchEventsUserSchools({
            variables: {
              eventId: parseInt(id, 10),
              userId: parseInt(currentUser.id, 10),
              schoolId: null,
            },
          });
        } else if (data5.joinEventByInvitation.result !== 'You already have access to this event.') {
          setErrorMessage(data5.joinEventByInvitation.result);
        }
      },
    },
  );

  const { data, loading, error } = useQuery(
    FETCH_EVENT,
    {
      variables: { id: parseInt(id, 10) },
      fetchPolicy: 'cache-and-network',
    },
  );
  const event = getOr({}, 'fetchEventDetail', data);

  // FIXME: this gets sent twice and i'm not sure why
  useEffect(() => {
    if (currentUser && param.invitation_code) {
      joinEventByInvitation({
        variables: {
          invitationCode: param.invitation_code,
        },
      });
    }
  }, [param.invitation_code, currentUser, joinEventByInvitation]);

  useEffect(() => {
    if (currentUser) {
      fetchEventTeams({
        variables: {
          eventId: id,
        },
      });
    }
  }, [currentUser, fetchEventTeams, id]);

  useEffect(() => {
    if (currentUser && id) {
      fetchOrdersByEvent({
        variables: {
          userId: parseInt(currentUser.id, 10),
          eventId: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, fetchOrdersByEvent, id]);

  useEffect(() => {
    if (currentUser && id) {
      fetchEventsUsersByUsers({
        variables: {
          userId: parseInt(currentUser.id, 10),
          eventId: parseInt(id, 10),
          schoolId: currentUser.fetchSchoolsUsers.length > 1 ? parseInt(params.schoolId, 10) : null,
        },
      });
    }
  }, [currentUser, fetchEventsUsersByUsers, id, params.schoolId]);

  const schoolId = params.schoolId || last(currentUser.fetchSchools)?.id;

  useEffect(() => {
    if (schoolId) {
      fetchEventUsersBySchool({
        variables: {
          eventId: parseInt(id, 10),
          schoolId: parseInt(schoolId, 10),
        },
      });
    }
  }, [fetchEventUsersBySchool, schoolId, id]);

  useEffect(() => {
    if (schoolId) {
      fetchTeamMembers({
        variables: {
          eventId: parseInt(id, 10),
          schoolId: parseInt(schoolId, 10),
        },
      });
    }
  }, [fetchTeamMembers, id, schoolId]);

  const [fetchEventsUsersCountByCoach, { data: eventsUserCount }] = useLazyQuery(
    FETCH_EVENTS_USERS_COUNT_BY_COACH,
  );

  const eventsUsersCoachCount = getOr([], 'fetchEventsUsersCountByCoach', eventsUserCount);

  useEffect(() => {
    if (currentUser && id && get('fetchSchoolsUsers', currentUser).length > 1) {
      fetchEventsUsersCountByCoach({
        variables: {
          eventId: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, fetchEventsUsersCountByCoach, id]);

  const [eventSchoolTeam, { data: currentTeam }] = useLazyQuery(
    FETCH_EVENTS_SCHOOL_TEAM,
  );

  const currentSchoolTeam = getOr([], 'eventSchoolTeam', currentTeam);

  useEffect(() => {
    if (currentUser && id && !isEmpty(get('fetchSchoolsUsers', currentUser))) {
      eventSchoolTeam({
        variables: {
          eventId: parseInt(id, 10),
          schoolId: params.schoolId ? parseInt(params.schoolId, 10)
            : parseInt(first(currentUser.fetchSchoolsUsers).fetchSchool.id, 10),
        },
      });
    }
  }, [currentUser, eventSchoolTeam, id, params.schoolId]);

  const toggleRegistration = () => setModalRegistration(!toggleRegistration);

  const toggleEvent = () => setModalEvent(!toggleEvent);

  const toggleReassignCaptain = () => setModalReassignCaptain(!toggleReassignCaptain);

  const [fetchUnpaidUserCount, { data: dataUnpaidUserCount }] = useLazyQuery(
    UNPAID_COUNT,
  );

  const unpaidUserCount = getOr(0, 'fetchUnpaidUserCount', dataUnpaidUserCount);

  const [fetchPendingRequest, { data: dataPendingRequestt }] = useLazyQuery(
    PENDING_REQUEST_COUNT,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const pendingRequestCount = getOr(0, 'fetchPendingRequest', dataPendingRequestt);

  const [userInvitationCount, { data: dataInvitationCount }] = useLazyQuery(
    USER_INVITATION_COUNT,
  );

  const invitationCount = getOr(0, 'userInvitationCount', dataInvitationCount);

  const [fetchPendingTermAndCondition, { data: dataTermAndConditionCount }] = useLazyQuery(
    PENDING_TERM_AND_CONDITION_COUNT,
  );

  const updateOrdersCache = useUpdateOrdersCache(getOr(0, 'id', currentUser), getOr(0, 'id', event));

  const pendingTermAndConditionCount = getOr(0, 'fetchPendingTermAndCondition', dataTermAndConditionCount);

  const toggleFinalizeTeamModal = () => setFinalizeTeamModal(!toggleFinalizeTeamModal);

  const toggleWithdrawCoachModal = () => setWithdrawCoachModal(!toggleWithdrawCoachModal);

  useHeaderComponentsMutation({
    title: 'MANAGE EVENT',
    backLink: '/events',
    components: [{ key: EVENT_TITLE, value: getOr('', 'title', event) }],
  });

  const [eventCancel] = useMutation(
    CANCEL_EVENT,
    {
      onCompleted: () => {
        updateCache(event, 'remove');
        updateEventCache(event.id);
        history.push('/events');
      },
    },
  );

  const cancelEvent = () => {
    eventCancel({
      variables: {
        id: parseInt(id, 10),
      },
    });
  };

  const reassignCaptainBeforeCancelRegistrationOption = () => {
    history.push(`/reassign-captain/${id}`);
  };

  const [registrationOptionCancel] = useMutation(
    CANCEL_REGISTRATION_OPTION,
    {
      refetchQueries: [{
        query: MANAGE_MY_COMPETITIONS,
        variables: {
          eventId: parseInt(id, 10),
          userId: parseInt(currentUser.id, 10),
        },
      }],
      onCompleted: () => {
        setModalRegistration(false);
        updateCache(event, 'remove');
        updateOrdersCache(null, 'empty');
        updateCacheEventsUsersByUsers(null, 'empty');
        history.push('/events');
      },
    },
  );

  const cancelRegistrationOption = () => {
    registrationOptionCancel({
      variables: {
        eventsUserId: parseInt(get('id', get('fetchEventsUser', order)), 10),
      },
    });
  };


  const [teamFinalize] = useMutation(
    TEAM_FINALIZE,
    {
      onCompleted: () => {
        setFinalizeTeamModal(false);
        updateCacheEventsUsersByUsers(eventsUser, 'remove');
        history.push('/events');
      },
    },
  );

  const finalizeTeam = () => {
    teamFinalize({
      variables: {
        eventId: parseInt(get('eventId', eventsUser), 10),
        schoolId: parseInt(get('schoolId', eventsUser), 10),
        status: 1,
      },
    });
  };

  const [withdrawCoachFromTeam] = useMutation(
    WITHDRAW_TEAM,
    {
      refetchQueries: [{
        query: FETCH_EVENTS_USERS_COUNT_BY_COACH,
        variables: {
          eventId: parseInt(id, 10),
        },
      },
      {
        query: FETCH_EVENTS_USER_SCHOOLS,
        variables: {
          eventId: parseInt(id, 10),
          userId: parseInt(currentUser.id, 10),
          schoolId: null,
        },
      }],
      onCompleted: () => {
        setWithdrawCoachModal(false);
        history.push('/events');
      },
    },
  );

  const withdrawCoach = () => {
    withdrawCoachFromTeam({
      variables: {
        eventId: parseInt(id, 10),
        schoolId: parseInt(params.schoolId, 10),
      },
    });
  };

  useEffect(() => {
    if (currentUser && eventsUser) {
      userInvitationCount({
        variables: {
          invitableId: parseInt(eventsUser.eventId, 10),
          invitableType: 'Event',
          userId: parseInt(currentUser.id, 10),
        },
      });
    }
  }, [currentUser, eventsUser, userInvitationCount, id]);

  useEffect(() => {
    if (currentUser && eventsUser) {
      fetchUnpaidUserCount({
        variables: {
          eventId: parseInt(get('eventId', eventsUser), 10),
          schoolId: parseInt(get('schoolId', eventsUser), 10),
        },
      });
    }
  }, [currentUser, eventsUser, fetchUnpaidUserCount, id]);

  useEffect(() => {
    if (currentUser && eventsUser) {
      fetchPendingRequest({
        variables: {
          eventId: parseInt(get('eventId', eventsUser), 10),
          schoolId: parseInt(get('schoolId', eventsUser), 10),
        },
      });
    }
  }, [currentUser, eventsUser, fetchPendingRequest, id]);

  useEffect(() => {
    if (currentUser && eventsUser) {
      fetchPendingTermAndCondition({
        variables: {
          eventId: parseInt(get('eventId', eventsUser), 10),
          schoolId: parseInt(get('schoolId', eventsUser), 10),
        },
      });
    }
  }, [currentUser, eventsUser, fetchPendingTermAndCondition, id]);


  const checkMembersLength = (members) => {
    if (!isEmpty(members) && members.length > 1) return true;
    return null;
  };

  if (loading || userLoading || eventUsersLoading || joinEventByInvitationLoading) {
    return <Loader />;
  }
  if (error || userError) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }

  return (
    <>
      {error
        ? <GenericAlert>{extractError(error)}</GenericAlert>
        : (
          <>
            {errorMessage
              ? <GenericAlert>{errorMessage}</GenericAlert>
              : null}
          </>
        )}
      {checkPermission(Permissions.CAN_VIEW_EVENT) && (
        <div className="row align-items-center">
          <div className="col-12 mx-auto px-4">
            <ListButton
              className="col-md-10 col-8 mt-4 px-4"
              onClick={() => {
                window.localStorage.removeItem('useOldEventPage', true);
                window.location.reload();
              }}
              type="button"
            >
              <TitleC>
                Try the new Admin Experience
              </TitleC>
            </ListButton>
          </div>
        </div>
      )}
      <div className="row align-items-center">
        <div className="col-12 mx-auto px-4">
          <div>
            {checkPermission(Permissions.CAN_COACH_ANOTHER_TEAM)
             && checkPermission(Permissions.CAN_JOIN_EVENT) && get('fetchSchoolsUsers', currentUser).length > 1
              ? (
                <>
                  <Input type="select" params={params} onChange={onChange} name="schoolId" id="schoolId">
                    { schoolsUsers.length > 0 && schoolsUsers.map((obj) => (
                      <option value={get('id', get('fetchSchool', obj))}>{get('name', get('fetchSchool', obj))}</option>
                    ))}
                  </Input>
                </>
              )
              : null}
          </div>
          <div className="pt-3 " />
          <div>
            { (eventsUser && checkPermissionofEventsUser(Permissions.CAN_MANAGE_TEAM_MEMBERS))
              || checkPermission(Permissions.CAN_JOIN_EVENT)
              ? (
                <>
                  <TEAMID>
                  Your MIST ID for this event:<br></br>
                  {' '}
                  {get('code', first(currentSchoolTeam))}-{get('code', currentUser)}
                  </TEAMID> 

                  <FontBold className="pt-3">
                    <p>Participate</p>
                    {event.discordLink ? (
                      <ListItem title="Join our Discord server to compete!" redirectLink={event.discordLink} checkPermission external />
                    ) : null}
                    {event.financialAidFormLink && <ListItem title="Apply for financial aid" redirectLink={event.financialAidFormLink} checkPermission external />}
                  </FontBold>

                  {checkPermission(Permissions.CAN_COACH_ANOTHER_TEAM)
                    && checkPermission(Permissions.CAN_JOIN_EVENT)
                    && (get('status', first(eventsUsers)) === 'active' || !get('teamFinalize', first(eventsUsers)))
                    && (
                      <ListItem title="Youth protection training for Coaches" redirectLink="https://docs.google.com/forms/d/e/1FAIpQLSe5DMaOswiETV7EqoRFj4yLEyqbcpUswThyJmv54f288e8vjg/viewform" checkPermission external />
                    )}

                  <FontBold className="pt-3">
                    <p>Status</p>
                  </FontBold>
                  {get('signIn', first(eventsUsers)) === false && !isEmpty(order)
                    ? <ListItem title="Terms and Conditions" redirectLink={`/order/${get('id', order)}/pending-terms-and-conditions`} checkPermission={(checkPermission(Permissions.CAN_JOIN_EVENT))} paymentStatus="pending" />
                    : null}
                  {!isEmpty(eventUsersBySchool)
                    ? <ListItem title="Team Requests" redirectLink={`/event-manage/${event.id}/team-member-requests/${schoolId}/requests`} checkPermission={(eventsUser && checkPermissionofEventsUser(Permissions.CAN_MANAGE_TEAM_MEMBERS))} unapproveSchoolsCount={pendingRequestCount} isCount />
                    : null}
                  {eventsUsers && get('status', last(eventsUsers)) && get('status', last(eventsUsers)) !== 'active'
                    ? <ListItem title="Team Approval" redirectLink={`/event-manage/${event.id}/team-approval/${get('id', last(eventsUsers))}`} checkPermission={(checkPermission(Permissions.CAN_JOIN_EVENT))} status={get('status', last(eventsUsers))} />
                    : null}
                  {!isEmpty(order)
                    ? <ListItem title="Payment" redirectLink={`/payment-status/${event.id}`} checkPermission={(checkPermission(Permissions.CAN_JOIN_EVENT))} paymentStatus={paymentStatus} />
                    : null}
                  <div className="d-none">
                    <ListItem className="hide" title="Financial Aid" redirectLink="/" checkPermission={(checkPermission(Permissions.CAN_VIEW_EVENT))} />
                    <ListItem title="Coalitions" redirectLink="/" checkPermission={(checkPermission(Permissions.CAN_VIEW_COALITION))} />
                  </div>
                </>
              )
              : null}
          </div>
          {(judgeEventsUser && judgeEventsUser.competitionsToJudge.length > 0) ? (
            <ListItem title="Sign the judge contract" redirectLink="https://form.jotform.com/233238153480150" checkPermission external />
          ) : null}
          <div>
            {checkPermission(Permissions.CAN_JOIN_COMPETITION)
              ? (
                <>
                  <FontBold className="pt-3">
                    <p>Competitions</p>
                  </FontBold>
                  <ListItem title="Manage My Competitions" redirectLink={`/events/${id}/my-competitions`} checkPermission={(checkPermission(Permissions.CAN_JOIN_COMPETITION))} />
                  <ListItem title="Competition Submissions" redirectLink={`/events/${id}/competition-submissions`} checkPermission={(checkPermission(Permissions.CAN_JOIN_COMPETITION))} />
                  <div className="d-none">
                    <ListItem title="View My Scores" redirectLink="/" checkPermission={(checkPermission(Permissions.CAN_JOIN_COMPETITION))} />
                  </div>
                </>
              )
              : null}
          </div>
          <div>
            { eventsUsers && get('status', last(eventsUsers)) === 'active'
              && (checkPermission(Permissions.CAN_JOIN_EVENT)
                || checkPermission(Permissions.CAN_VIEW_COMPETITION_ROSTER)
                || checkPermission(Permissions.CAN_VIEW_COACH))
              ? (
                <>
                  <FontBold className="pt-3">
                    <p>Team {get('code', first(currentSchoolTeam))}</p>
                  </FontBold>
                  <ListItem isCount userCount={totalMembers} title="View My Team" redirectLink={`/events/${id}/team/${get('schoolId', params)}`} checkPermission={(checkPermission(Permissions.CAN_JOIN_EVENT))} />
                  <ListItem title="View Team Coaches" redirectLink={`/events/${id}/coach/${get('schoolId', params)}`} checkPermission={(checkPermission(Permissions.CAN_VIEW_COACH))} />
                  <ListItem title="Competition Roster" redirectLink={`/events/${id}/competition-roster/${get('schoolId', params)}`} checkPermission={(checkPermission(Permissions.CAN_VIEW_COMPETITION_ROSTER))} />
                  <ListItem title="Invite Team Member" redirectLink={`/event-manage/${event.id}/invite-existing-user/student`} checkPermission={(!get('teamFinalize', eventsUser) && checkPermission(Permissions.CAN_JOIN_EVENT) && checkPermission(Permissions.CAN_INVITE_TEAM_MEMBER))} />
                </>
              )
              : null}
          </div>
          <div>
            { checkPermission(Permissions.CAN_COACH_ANOTHER_TEAM)
              && checkPermission(Permissions.CAN_JOIN_EVENT)
              && (get('status', first(eventsUsers)) === 'active'
              || !get('teamFinalize', first(eventsUsers)))
              ? (
                <>
                  <FontBold className="pt-3">
                    <p>Coach Controls</p>
                  </FontBold>
                  <ListItem
                    title="Coach Another Team"
                    redirectLink={`/event-manage/${event.id}/coach-another-team`}
                    checkPermission={(checkPermission(Permissions.CAN_JOIN_EVENT)
                    && checkPermission(Permissions.CAN_COACH_ANOTHER_TEAM)
                    && get('status', first(eventsUsers)) === 'active')}
                  />
                  <CancelItem
                    title="Withdraw from Current Team"
                    onClick={() => setWithdrawCoachModal(true)}
                    imageAttached={RIGHT_ARROW}
                    checkPermission={checkPermission(Permissions.CAN_JOIN_EVENT)
                    && checkPermission(Permissions.CAN_COACH_ANOTHER_TEAM)
                    && eventsUsersCoachCount > 1
                    && !get('teamFinalize', first(eventsUsers))}
                  />
                </>
              )
              : null}
          </div>
          <div>
            { (!get('teamFinalize', eventsUser) && (checkPermissionofEventsUser(Permissions.CAN_MANAGE_TEAM_MEMBERS)
              || checkPermissionofEventsUser(Permissions.CAN_ADD_REQUEST_COALITION)))
              ? (
                <>
                  <FontBold className="pt-3">
                    <p>Captain Controls</p>
                  </FontBold>
                  <ListItem
                    title="Reassign Captain"
                    redirectLink={`/reassign-captain/${id}`}
                    checkPermission={!get('teamFinalize', eventsUser)
                        && checkPermissionofEventsUser(Permissions.CAN_MANAGE_TEAM_MEMBERS)}
                  />
                  <CancelItem
                    title="Finalize Team"
                    onClick={() => setFinalizeTeamModal(true)}
                    imageAttached={RIGHT_ARROW}
                    checkPermission={(!get('teamFinalize', eventsUser) && checkPermissionofEventsUser(Permissions.CAN_MANAGE_TEAM_MEMBERS))}
                  />
                  <div className="d-none">
                    <ListItem title="Apply For Coalition" redirectLink="/" checkPermission={(checkPermission(Permissions.CAN_ADD_REQUEST_COALITION))} />
                  </div>
                </>
              )
              : null}
          </div>
          { checkPermission(Permissions.CAN_VIEW_REPORT_PAYMENT)
              || checkPermission(Permissions.CAN_VIEW_REPORT_COUPON)
              || checkPermission(Permissions.CAN_VIEW_REPORT_COMPETITION)
            ? (
              <>
                <FontBold className="pt-3">
                  <p>Reports</p>
                </FontBold>
                <ListItem title="Payments" redirectLink={`/events/${id}/payment-report`} checkPermission={(checkPermission(Permissions.CAN_VIEW_REPORT_PAYMENT))} />
                <ListItem title="Coupons" redirectLink={`/events/${id}/coupons`} checkPermission={(checkPermission(Permissions.CAN_VIEW_REPORT_COUPON))} />
                <ListItem title="Competitions" redirectLink={`/events/${id}/competitions-report`} checkPermission={(checkPermission(Permissions.CAN_VIEW_REPORT_COMPETITION))} />
                <ListItem title="Special Need" redirectLink={`/events/${id}/special-need-reports`} checkPermission={(checkPermission(Permissions.CAN_VIEW_REPORT_SPECIAL_NEED))} />
                <ListItem title="Registration" redirectLink={`/events/${id}/registration-report`} checkPermission={(checkPermission(Permissions.CAN_VIEW_REPORT_PAYMENT))} />
                <ListItem title="Spirit Points" redirectLink={`/events/${id}/spirit-points-report`} checkPermission={(checkPermission(Permissions.CAN_VIEW_REPORT_COMPETITION))} />
                <ListItem title="Attendance" redirectLink={`/events/${id}/attendance-report`} checkPermission={(checkPermission(Permissions.CAN_VIEW_REPORT_COMPETITION))} />
              </>
            )
            : null}
          <div>
            { checkPermission(Permissions.CAN_VIEW_EVENT)
              ? (
                <>
                  <FontBold className="pt-3">
                    <p>Requests</p>
                  </FontBold>
                  <ListItem title="Schools" redirectLink={`/event-manage/${id}/unapprove-schools`} checkPermission={(checkPermission(Permissions.CAN_VIEW_EVENT))} unapproveSchoolsCount={event.pendingSchoolRequestCount} isCount />
                  <ListItem title="Captains" redirectLink={`/event-manage/${event.id}/unapprove-teams`} checkPermission={(checkPermission(Permissions.CAN_VIEW_EVENT))} unapproveSchoolsCount={event.activeSchoolRequestCount} isCount />
                  <div className="d-none">
                    <ListItem title="Financial Aid" redirectLink="/" checkPermission={(checkPermission(Permissions.CAN_VIEW_EVENT))} />
                    <ListItem title="Transactions/Refund" redirectLink="/" checkPermission={(checkPermission(Permissions.CAN_VIEW_EVENT))} />
                  </div>
                </>
              )
              : null}
          </div>
          <div>
            { checkPermission(Permissions.CAN_VIEW_EVENT)
              ? (
                <>
                  <FontBold className="pt-3">
                    <p>Users</p>
                  </FontBold>
                  {!isEmpty(eventTeams)
                    ? <ListItem title="Teams" redirectLink={`/event/${id}/teams`} checkPermission={(checkPermission(Permissions.CAN_VIEW_EVENT))} />
                    : null}

                  <ListItem title="Students" redirectLink={`/event/${id}/students`} checkPermission={(checkPermission(Permissions.CAN_VIEW_EVENT))} />
                  <ListItem title="Coaches" redirectLink={`/event/${id}/coaches`} checkPermission={(checkPermission(Permissions.CAN_VIEW_EVENT))} />
                  <ListItem title="Guests" redirectLink={`/event/${id}/guests`} checkPermission={(checkPermission(Permissions.CAN_VIEW_EVENT))} />
                  <ListItem title="Coalitions" redirectLink={`/event-manage/${id}/coalitions`} checkPermission={event.allowCoalition && (checkPermission(Permissions.CAN_VIEW_EVENT))} />
                  <ListItem title="Judges" redirectLink={`/event/${id}/judges`} checkPermission={(checkPermission(Permissions.CAN_VIEW_JUDGE))} />
                  <ListItem title="Volunteers" redirectLink={`/event/${id}/volunteers`} checkPermission={(checkPermission(Permissions.CAN_VIEW_EVENT))} />
                  <ListItem title="Admins" redirectLink={`/events/${id}/admins`} checkPermission={(checkPermission(Permissions.CAN_VIEW_EVENT))} />
                </>
              )
              : null}
          </div>
          <div>
            {judgeEventsUser && judgeEventsUser.competitionsToJudge.length > 0
              ? (
                <CompsToJudge eventId={id} judgeEventsUser={judgeEventsUser} />
              )
              : null}
          </div>
          <div>
            { checkPermission(Permissions.CAN_MANAGE_COMPETITION)
              || checkPermission(Permissions.CAN_EXPORT_SCORE)
              || checkPermission(Permissions.CAN_VIEW_SCORE)
              ? (
                <>
                  <FontBold className="pt-3">
                    <p>Competition Admin Controls</p>
                  </FontBold>
                  <div className="d-none">
                    <ListItem title="Rounds" redirectLink="/" checkPermission={(checkPermission(Permissions.CAN_MANAGE_COMPETITION))} />
                    <ListItem title="Matches" redirectLink="/" checkPermission={(checkPermission(Permissions.CAN_MANAGE_COMPETITION))} />
                    <ListItem title="Judged" redirectLink="/" checkPermission={(checkPermission(Permissions.CAN_MANAGE_COMPETITION))} />
                    <ListItem title="Scoring Progress" redirectLink="/" checkPermission={(checkPermission(Permissions.CAN_EXPORT_SCORE))} />
                    <ListItem title="Release All Ballots" redirectLink="/" checkPermission={(checkPermission(Permissions.CAN_MANAGE_COMPETITION))} />
                  </div>
                  {/* <ListItem title="Competition Scores" redirectLink={`/events/${id}/scoring`} checkPermission={(checkPermission(Permissions.CAN_VIEW_SCORE))} /> */}
                  <ListItem title="Scoring Progress" redirectLink={`/events/${id}/score-progress`} checkPermission={(checkPermission(Permissions.CAN_EXPORT_SCORE))} />
                  <ListItem title="Overall Scores" redirectLink={`/events/${id}/overall-scores`} checkPermission={(checkPermission(Permissions.CAN_VIEW_SCORE))} />
                </>
              )
              : null}
          </div>
          <div>
            { checkPermission(Permissions.CAN_VIEW_EVENT)
              ? (
                <>
                  <FontBold className="pt-3">
                    <p>Edit Event</p>
                  </FontBold>
                  <ListItem title="Event Info" redirectLink={`/events/${id}/edit`} checkPermission={(checkPermission(Permissions.CAN_VIEW_EVENT))} />
                  <ListItem title="Settings" redirectLink={`/events/${id}/settings`} checkPermission={(checkPermission(Permissions.CAN_VIEW_EVENT))} />
                  <ListItem title="Registration Options" redirectLink={`/events/${id}/registration-options`} checkPermission={(checkPermission(Permissions.CAN_VIEW_EVENT))} />
                  <ListItem title="Competitions" redirectLink={`/events/${id}/event-competitions`} checkPermission={(checkPermission(Permissions.CAN_VIEW_EVENT))} />
                  <ListItem title="Locations" redirectLink={`/events/${id}/event-locations`} checkPermission={(checkPermission(Permissions.CAN_VIEW_EVENT))} />
                  <ListItem title="Schedule" redirectLink={`/events/${id}/event-schedule`} checkPermission={(checkPermission(Permissions.CAN_VIEW_EVENT))} />
                  <ListItem title="Announcements" redirectLink={`/events/${id}/event-announcements`} checkPermission={(checkPermission(Permissions.CAN_VIEW_EVENT))} />
                  <ListItem title="Resources" redirectLink={`/events/${id}/event-resources`} checkPermission={(checkPermission(Permissions.CAN_VIEW_EVENT))} />
                  <ListItem title="Live Event Dashboard" redirectLink={`/events/${id}/live`} checkPermission={(checkPermission(Permissions.CAN_VIEW_EVENT))} />
                </>
              )
              : null}
          </div>
          <div className="mt-5">
            { checkPermission(Permissions.CAN_JOIN_EVENT)
              ? (
                <>
                  {get('signIn', first(eventsUsers)) === true
                    ? <ListItem title="View Terms & Conditions" redirectLink={`/events/${id}/terms-and-conditions`} checkPermission={(checkPermission(Permissions.CAN_JOIN_EVENT))} />
                    : null}
                  {eventsUsers && !get('teamFinalize', last(eventsUsers)) && !isEmpty(order) && get('status', order) !== 'complete' && isEmpty(get('transactionId', order), order)
                    ? (
                      <>
                        {isEmpty(eventsUser)
                          ? (
                            <CancelItem
                              title="Cancel Registration"
                              onClick={() => setModalRegistration(true)}
                              imageAttached={RIGHT_ARROW}
                              checkPermission={(checkPermission(Permissions.CAN_JOIN_EVENT))}
                            />
                          )
                          : (
                            <>
                              {!isEmpty(eventsUser) && checkMembersLength(teamMembers)
                                ? (
                                  <CancelItem
                                    title="Cancel Registration"
                                    onClick={() => setModalReassignCaptain(true)}
                                    imageAttached={RIGHT_ARROW}
                                    checkPermission={(checkPermission(Permissions.CAN_JOIN_EVENT))}
                                  />
                                )
                                : (
                                  <CancelItem
                                    title="Cancel Registration"
                                    onClick={() => setModalRegistration(true)}
                                    imageAttached={RIGHT_ARROW}
                                    checkPermission={(checkPermission(Permissions.CAN_JOIN_EVENT))}
                                  />
                                )}
                            </>
                          )}
                      </>
                    )
                    : null}
                </>
              )
              : null}
          </div>
        </div>
      </div>
      <CancelRegistrationOption
        modal={modalRegistration}
        toggle={toggleRegistration}
        onClick={() => cancelRegistrationOption()}
        OnSelect={() => setModalRegistration(false)}
      />
      <CancelEvent
        modal={modalEvent}
        toggle={toggleEvent}
        onClick={() => cancelEvent()}
        onSelect={() => setModalEvent(false)}
      />
      <ReassignCaptainBeforeCancelRegistration
        modal={modalReassignCaptain}
        toggle={toggleReassignCaptain}
        onClick={() => reassignCaptainBeforeCancelRegistrationOption()}
        onSelect={() => setModalReassignCaptain(false)}
      />
      <PopUpModal
        isOpen={finalizeTeamModal}
        toggle={toggleFinalizeTeamModal}
        onAccept={finalizeTeam}
        onCancel={() => setFinalizeTeamModal(false)}
        headerText="Finalize Team"
        unpaidUserCount={unpaidUserCount}
        pendingRequestCount={pendingRequestCount}
        pendingInvitationRequestCount={invitationCount}
        pendingTermAndConditionCount={pendingTermAndConditionCount}
        bodyText="Are you sure to finalize Team?"
        eventId={id}
        schoolId={schoolId}
      />
      <PopUpModal
        isOpen={withdrawCoachModal}
        toggle={toggleWithdrawCoachModal}
        onAccept={withdrawCoach}
        onCancel={() => setWithdrawCoachModal(false)}
        headerText="Withdraw from Current Team"
        bodyText="Are you sure to withdraw team?"
        eventId={id}
      />
      {checkPermission('Add Event')
        ? (
          <>
            <div className="pt-3" />
            <CancelItem
              title="Cancel Event"
              onClick={() => setModalEvent(true)}
              imageAttached={RIGHT_ARROW}
              checkPermission={
                (!get('teamFinalize', eventsUser)
                  && checkPermission(Permissions.CAN_ADD_EVENT)
                )
              }
              color="red"
            />
            <hr />
          </>
        )
        : null}

    </>
  );
};
export default EventManage;
