import React, { useState } from 'react';
import { gql } from '@apollo/client';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { isEmpty } from 'lodash/fp';
import ReactExport from 'react-export-excel';
import moment from 'moment';
import {
  FontBold,
  PrimaryButton,
} from '../../Components/Auth/Layout';
import { FETCH_MY_EVENTS, SEARCH_EVENTS } from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import Loader from '../../Components/Loader';
import GenericAlert from '../../Components/GenericAlert';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useCurrentUser from '../../Hooks/userCurrentUser';
import List from '../../Components/List/Index';
import { ButtonLink } from '../../Components/Form';
import ADD_SMALL_ICON from '../../Images/icons/add-small.png';
import Search from '../../Components/Search/Index';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';

const { ExcelFile } = ReactExport;
const { ExcelColumn, ExcelSheet } = ReactExport.ExcelFile;

const Export = ({ eventData }) => (
  <ExcelFile
    filename={`myMIST Overall Registration (${moment().format('YYYY-MM-DD')})`}
    hideElement
  >
    <ExcelSheet
      data={eventData}
      name="Registration Data"
    >
      <ExcelColumn label="Event Title" value="title" />
      <ExcelColumn label="Event Status" value="status" />
      <ExcelColumn label="Registration Start" value="regStartDate" />
      <ExcelColumn label="Registration Deadline" value="regDeadline" />
      <ExcelColumn label="Total Students" value="totalStudents" />
      <ExcelColumn label="Total Coaches" value="totalCoaches" />
      <ExcelColumn label="Total Guests" value="totalGuests" />
      <ExcelColumn label="Paid Students" value="paidStudents" />
      <ExcelColumn label="Paid Coaches" value="paidCoaches" />
      <ExcelColumn label="Paid guests" value="paidGuests" />
    </ExcelSheet>
  </ExcelFile>
);

const OVERALL_REGISTRATION_DATA = gql`
  query overallRegistration($eventIds: [Int!]!) {
    overallRegistration(eventIds: $eventIds) {
      eventId
      paidGuests
      paidCoaches
      paidStudents
      totalCoaches
      totalGuests
      totalStudents
    }
  }
`;

const getRegDeadline = (event) => event.fetchRegistrationOptions
  .filter((ro) => ro.roleId === 3)
  .map((ro) => ro.finalDeadline)[0];

const ExportButton = ({ events }) => {
  const eventsById = events?.reduce((acc, evnt) => ({
    ...acc,
    [evnt.id]: evnt,
  }), {}) ?? {};

  const [fetchOverallRegistrationData, { data, loading }] = useLazyQuery(OVERALL_REGISTRATION_DATA);
  const sheetData = data?.overallRegistration.map((stats) => {
    const event = eventsById[stats.eventId];
    return ({
      ...stats,
      title: event.title,
      status: event.status,
      regStartDate: event.regStartDate,
      regDeadline: getRegDeadline(event),
    });
  }) ?? [];

  return (
    <>
      <PrimaryButton
        color="secondary"
        loading={loading}
        disabled={loading}
        onClick={() => fetchOverallRegistrationData({
          variables: {
            eventIds: Object.keys(eventsById),
          },
        })}
      >
        {loading ? 'Loading...' : 'Export Overall Registration Stats'}
      </PrimaryButton>
      {data && <Export eventData={sheetData} />}
    </>
  );
};

const EventIndex = () => {
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);
  const checkPermission = useUserPermissionChecker(currentUser);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const { loading, error } = useQuery(
    FETCH_MY_EVENTS, {
      onCompleted: (data) => {
        setFilteredEvents(data.myEvents);
      },
    },
  );
  const extractError = useGraphQLErrorExtractor();
  useHeaderComponentsMutation({
    title: 'EVENTS',
    backLink: '/dashboard',
  });

  const [eventsSearch] = useLazyQuery(
    SEARCH_EVENTS,
    {
      onCompleted: (data) => {
        setFilteredEvents(data.searchEvents.filter(({ status }) => status === 'active'));
      },
    },
  );

  const searchEvents = (e) => {
    eventsSearch({
      variables: { query: e.target.value },
    });
  };

  if (loading || userLoading) {
    return <Loader />;
  }
  if (error || userError) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }

  return (
    <>
      <div className="mt-4" />
      <Search
        placeholder="Search"
        onChange={(e) => searchEvents(e)}
      />
      {isEmpty(filteredEvents)
        ? <p>No Events</p>
        : null}
      <div className="row">
        <div className="col-12 mx-auto px-4">
          <FontBold>
            <p className="mt-4">
              Events(
              {filteredEvents.length}
              )
            </p>
          </FontBold>
        </div>
      </div>
      {currentUser.fetchRole.id <= 2 && filteredEvents.length > 1 && (
        <div>
          <ExportButton events={filteredEvents} />
        </div>
      )}
      <List data={filteredEvents} displayAttribute="title" redirectLink="event-manage" redirectOperation="" dataType="events" />
      {/* TODO: change me once we have "import from past event" functionality
        *       this used to go to `/event-detail`
        */}
      <ButtonLink loading={loading} htmltype="submit" backgroundcolor="#F4AB37" fontColor="#FFF" border="none" addNewText="Add New Event" addNewLink="/events/new" imageAttached={ADD_SMALL_ICON} checkPermission={(checkPermission('Add Event'))} />
    </>
  );
};
export default EventIndex;
