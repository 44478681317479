import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  Select,
  Spinner,
  VStack,
  useToast,
} from '@chakra-ui/react';

const LOAD_SCHOOLS = gql`
  query fetchSchools($eventId: ID!) {
    fetchSchools(tenantId: 1) {
      id
      name
      status
    }
    eventTeams(eventId: $eventId) {
      id
      name
      status
    }
  }
`;

const useSchools = (eventId) => {
  const { data, loading } = useQuery(LOAD_SCHOOLS, {
    variables: {
      eventId,
    },
  });

  if (loading) {
    return { loading };
  }

  const allSchools = data.fetchSchools;
  const schoolsInEvent = data.eventTeams;

  const schoolsById = allSchools.reduce((acc, school) => ({
    ...acc,
    [school.id]: school,
  }), {});

  const schoolIdsInEvent = schoolsInEvent.reduce((acc, school) => ({
    ...acc,
    [school.id]: true,
  }), {});

  const schoolsNotInEvent = allSchools
    .filter((s) => s.status === 'active')
    .filter((s) => !schoolIdsInEvent[s.id]);

  schoolsInEvent.sort((s1, s2) => s1.name.localeCompare(s2.name));
  schoolsNotInEvent.sort((s1, s2) => s1.name.localeCompare(s2.name));

  return {
    loading: false,
    schoolsById,
    schoolsInEvent,
    schoolsNotInEvent,
  };
};

const CHANGE_SCHOOL = gql`
  mutation changeSchoolInEvent($input: ChangeSchoolInEventInput!) {
    changeSchoolInEvent(input: $input) {
      warnings
      eventUser {
        id
        status
        schoolId
        fetchTeam {
          id
          code
          school {
            id
            name
          }
        }
        fetchSchool {
          id
          name
        }
        fetchSchoolUser {
          status
          fetchUserAttachment {
            id
            verificationFileUrl
          }
          fetchSchool {
            id
            name
          }
        }
        status
      }
    }
  }
`;

export const ChangeSchoolModal = ({
  isOpen,
  onClose,
  eventId,
  currentSchoolId,
  eventUserId,
  userName,
}) => {
  const toast = useToast();
  const [selectedSchool, setSelectedSchool] = useState();

  const {
    schoolsById,
    schoolsInEvent,
    schoolsNotInEvent,
    loading,
  } = useSchools(eventId);

  const onSelect = (e) => {
    const sId = e.target.value;
    setSelectedSchool(schoolsById[sId]);
  };

  const [changeSchool, { loading: changeSchoolLoading }] = useMutation(CHANGE_SCHOOL);
  const onConfirm = () => {
    changeSchool({
      variables: {
        input: {
          eventUserId,
          schoolId: selectedSchool.id,
        },
      },
    }).then((res) => {
      toast({
        title: `Successfully changed ${userName} to ${selectedSchool.name}`,
        status: 'success',
        isClosable: true,
        position: 'top',
      });

      const { warnings } = res.data.changeSchoolInEvent;
      if (warnings.length > 0) {
        toast({
          title: warnings.join('. '),
          status: 'warning',
          isClosable: true,
          position: 'top',
        });
      }

      onClose();
    }).catch((e) => {
      toast({
        title: e.graphQLErrors?.[0]?.message ?? e.message,
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Change School</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack>
            {loading ? (
              <Spinner />
            ) : (
              <Select onChange={onSelect}>
                <option disabled>────Existing Teams────</option>
                {schoolsInEvent.map((school) => (
                  <option
                    value={school.id}
                    selected={school.id === selectedSchool?.id
                    || (!selectedSchool && Number.parseInt(school.id, 10) === currentSchoolId)}
                  >
                    {school.name}
                  </option>
                ))}
                <option disabled>────Other Schools────</option>
                {schoolsNotInEvent.map((school) => (
                  <option value={school.id}>
                    {school.name}
                  </option>
                ))}
              </Select>
            )}
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onConfirm} isLoading={changeSchoolLoading} disabled={!selectedSchool}>
            Change School
          </Button>
          <Button colorScheme="gray" variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

ChangeSchoolModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
  eventUserId: PropTypes.number.isRequired,
  currentSchoolId: PropTypes.number.isRequired,
  userName: PropTypes.string.isRequired,
};
