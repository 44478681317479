import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { getOr } from 'lodash/fp';
import { CREATE_COMPETITION } from '../../GraphQL/Mutations';
import useFormParams from '../../Hooks/useFormParams';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import GenericAlert from '../../Components/GenericAlert';
import useCurrentUser from '../../Hooks/userCurrentUser';
import useUpdateCompetitionsCache from '../../Hooks/useUpdateCompetitionsCache';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import Form from './Form';

const CompetitionNew = () => {
  const history = useHistory();
  const { currentUser } = useCurrentUser(true);
  const { id } = useParams();
  const updateCache = useUpdateCompetitionsCache(getOr(0, 'tenantId', currentUser), parseInt(id, 10));
  const checkPermission = useUserPermissionChecker(currentUser);
  const [createCompetition, { loading, error }] = useMutation(
    CREATE_COMPETITION,
    {
      onCompleted: (data) => {
        updateCache(data.createCompetition.competition);
        history.goBack();
      },
    },
  );
  useHeaderComponentsMutation({
    backLink: `/competitions/${id}`,
    title: 'ADD NEW COMPETITION',
  });
  const { params, onChange, setParams } = useFormParams({
    title: '',
    matchup: false,
    group: false,
    genderEnable: false,
    onlineSubmission: false,
    minTeam: 0,
    maxTeam: 0,
    maxSubmission: 0,
    description: '',
    requiresAdditionalCompetition: false,
  });
  const extractError = useGraphQLErrorExtractor();

  const onSubmit = (e) => {
    e.preventDefault();
    params.minTeam = parseInt(params.minTeam, 10);
    params.maxTeam = parseInt(params.maxTeam, 10);
    params.maxSubmission = parseInt(params.maxSubmission, 10);
    params.ballotId = parseInt(params.ballotId, 10);
    createCompetition({
      variables: {
        ...params,
        tenantId: currentUser.tenantId,
        categoryId: parseInt(id, 10),
      },
    });
  };

  useEffect(() => {
    if (currentUser) {
      if (checkPermission('Add Competition') === undefined) {
        history.push(`/competitions/${parseInt(id, 10)}`);
      }
    }
  }, [currentUser, checkPermission, history, id]);

  return (
    <>
      <div className="row align-items-center">
        <div className="col-12 mx-auto px-4">
          {error ? <GenericAlert>{extractError(error)}</GenericAlert> : null}
          <Form
            onSubmit={onSubmit}
            onChange={onChange}
            params={params}
            loading={loading}
            setParams={setParams}
            formType="new"
            currentUser={currentUser}
          />
        </div>
      </div>
      <hr />
    </>
  );
};
export default CompetitionNew;
