import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams, Link as ReactRouterLink } from 'react-router-dom';
import {
  Alert,
  Badge as ChakraBadge,
  Box,
  Button,
  Flex,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  VStack,
  GridItem,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { gql } from '@apollo/client';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  FETCH_EVENT,
  FETCH_EVENTS_USERS_BY_USERS,
} from '../../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../../Hooks/useHeaderComponentsMutation';
import { EVENT_TITLE } from '../../../Constants/AppConstants';

import appConfigIcon from '../../../Images/event-manage-v2/app-config.png';
import configIcon from '../../../Images/event-manage-v2/configuration.png';
import eventStatusIcon from '../../../Images/event-manage-v2/event-status.png';
import reportsIcon from '../../../Images/event-manage-v2/reports.png';
import requestsIcon from '../../../Images/event-manage-v2/requests.png';
import scorekeepingIcon from '../../../Images/event-manage-v2/scorekeeping.png';
import usersIcon from '../../../Images/event-manage-v2/users.png';

import { Card } from './Card';
import { JudgingCard } from './JudgingCard';
import { QuickUserLookup } from './QuickUserLookup';
import useUpdateEventsByUserCache from '../../../Hooks/useUpdateEventsByUserCache';
import useUpdateActiveEventsCache from '../../../Hooks/useUpdateActiveEventsCache';
import useCurrentUser from '../../../Hooks/userCurrentUser';

const NumberBadge = ({ amount }) => {
  if (amount) {
    return <ChakraBadge colorScheme="red" variant="solid">{amount}</ChakraBadge>;
  }
  return null;
};

const LinkWithDescription = ({
  title, to, description, Badge,
}) => (
  <VStack align="start" gap={0}>
    <Box>
      {to ? (
        <Link
          as={ReactRouterLink}
          to={to}
          color="blue.500"
          textDecoration="underline"
        >
          {title}
        </Link>
      ) : (
        <Text style={{ textDecoration: 'underline' }}>{title}</Text>
      )}
      {Badge && (
        <Box display="inline" paddingInlineStart={1}>
          <Badge />
        </Box>
      )}
    </Box>
    <Text style={{ marginTop: '-2px' }}>{description}</Text>
  </VStack>
);

const Noop = () => null;

const LinkCard = ({
  imgSrc, title, links, event,
}) => (
  <Card imgSrc={imgSrc} title={title}>
    {links.map((link) => {
      const Badge = link.Badge ?? Noop;
      return (
        <LinkWithDescription
          key={link.title}
          title={link.title}
          to={typeof link.to === 'function' ? link.to({ event }) : link.to}
          description={link.description}
          Badge={() => <Badge event={event} />}
        />
      );
    })}
  </Card>
);

const makeLinkCard = (config) => (
  ({ event }) => (
    <LinkCard
      event={event}
      title={config.title}
      imgSrc={config.imgSrc}
      links={config.links}
    />
  )
);
const UsersCard = makeLinkCard({
  title: 'Users',
  imgSrc: usersIcon,
  links: [
    {
      title: 'Teams',
      to: ({ event }) => `/event/${event.id}/teams`,
      description: 'View teams, edit competition rosters, remove users',
    },
    {
      title: 'Coalitions',
      to: ({ event }) => `/event-manage/${event.id}/coalitions`,
      description: 'Group schools together for specific competitions',
    },
    {
      title: 'Students',
      to: ({ event }) => `/event/${event.id}/students`,
      description: 'View students in event',
    },
    {
      title: 'Coaches',
      to: ({ event }) => `/event/${event.id}/coaches`,
      description: 'View coaches in event',
    },
    {
      title: 'Guests',
      to: ({ event }) => `/event/${event.id}/guests`,
      description: 'View guests in event',
    },
    {
      title: 'Judges',
      to: ({ event }) => `/event/${event.id}/judges`,
      description: 'Add judges for competitions',
    },
    {
      title: 'Organizers',
      to: ({ event }) => `/event/${event.id}/volunteers`,
      description: 'Add and remove admins and volunteers to your event',
    },
  ],
});
const ReportsCard = makeLinkCard({
  title: 'Reports',
  imgSrc: reportsIcon,
  links: [
    {
      title: 'Registration',
      to: ({ event }) => `/events/${event.id}/registration-report`,
      description: 'Registration and team information',
    },
    {
      title: 'Competitions',
      to: ({ event }) => `/events/${event.id}/competitions-report`,
      description: 'Competitions competitors are registered for',
    },
    {
      title: 'Payments',
      to: ({ event }) => `/events/${event.id}/payment-report`,
      description: 'Review overall payments made',
    },
    {
      title: 'Coupons',
      to: ({ event }) => `/events/${event.id}/coupons`,
      description: 'Review overall coupons used',
    },
    {
      title: 'Special Accomodations',
      to: ({ event }) => `/events/${event.id}/special-need-reports`,
      description: 'Review all special accommodations required by users',
    },
    {
      title: 'Spirit Points',
      to: ({ event }) => `/events/${event.id}/spirit-points-report`,
      description: 'Spirit Points granted and deducted from participants',
    },
  ],
});
const RequestsCard = makeLinkCard({
  title: 'Requests',
  imgSrc: requestsIcon,
  links: [
    {
      title: 'Schools',
      to: ({ event }) => `/event-manage/${event.id}/unapprove-schools`,
      description: 'Approve or deny requests to review school requests',
      Badge: ({ event }) => <NumberBadge amount={event.pendingSchoolRequestCount} />,
    },
    {
      title: 'Captains',
      to: ({ event }) => `/event-manage/${event.id}/unapprove-teams`,
      description: 'Approve or deny requests to review captain requests',
      Badge: ({ event }) => <NumberBadge amount={event.activeSchoolRequestCount} />,
    },
  ],
});
const ScorekeepingCard = makeLinkCard({
  title: 'Scorekeeping',
  imgSrc: scorekeepingIcon,
  links: [
    {
      title: 'Scoring Progress',
      to: ({ event }) => `/events/${event.id}/score-progress`,
      description: 'View and edit scores entered by judges',
    },
    {
      title: 'Ballots (coming soon)',
      description:
        'Review ballots entered by judges and send them to students',
    },
  ],
});
const ConfigCard = makeLinkCard({
  title: 'Configuration',
  imgSrc: configIcon,
  links: [
    {
      title: 'Event Info',
      to: ({ event }) => `/events/${event.id}/edit`,
      description: 'Manage overall event information and details.',
    },
    {
      title: 'Event Settings',
      to: ({ event }) => `/events/${event.id}/settings`,
      description: 'Enable or disable event-specific configuration settings.',
    },
    {
      title: 'Registration Options',
      to: ({ event }) => `/events/${event.id}/registration-options`,
      description: 'Add options for users when they register for your event.',
    },
    {
      title: 'Competitions',
      to: ({ event }) => `/events/${event.id}/event-competitions`,
      description: 'Define which competitions will be set up.',
    },
    {
      title: 'Generate Coupons',
      to: '/coupons',
      description: 'Create a code for user to modify amount to register.',
    },
  ],
});
const AppConfigCard = makeLinkCard({
  title: 'GetMistified Configuration',
  imgSrc: appConfigIcon,
  links: [
    {
      title: 'Locations',
      to: ({ event }) => `/events/${event.id}/event-locations`,
      description: 'Configure locations used by event schedule on the app',
    },
    {
      title: 'Schedule',
      to: ({ event }) => `/events/${event.id}/event-schedule`,
      description: 'Update event schedule shown on the app',
    },
    {
      title: 'Announcements',
      to: ({ event }) => `/events/${event.id}/event-announcements`,
      description: 'Push notifications sent to users on the app',
    },
    {
      title: 'Resources',
      to: ({ event }) => `/events/${event.id}/event-resources`,
      description:
        'Links, photos, and documents made available to users on the app',
    },
    {
      title: 'Live Dashboard',
      to: ({ event }) => `/events/${event.id}/live`,
      description:
        'View Schedule and Announcements on an extended display',
      Badge: ({ event }) => (event.status === 'active' ? <ChakraBadge colorScheme="green">Tournament Active</ChakraBadge> : null),
    },
  ],
});

const SET_EVENT_STATUS = gql`
  mutation setEventStatus($id: ID!, $status: String!) {
    setEventStatus(input: {
      id: $id,
      status: $status,
    }) {
      event {
        id
        status
      }
    }
  }
`;

const CancelEventModal = ({
  eventId,
  isOpen,
  onClose,
  onCompleted,
}) => {
  const [setEventStatus, { loading }] = useMutation(
    SET_EVENT_STATUS, {
      onCompleted,
      variables: {
        id: eventId,
        status: 'cancelled',
      },
    },
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Cancel Event</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Are you sure you want to cancel your event?
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="gray" mr={3} onClick={onClose}>
            No, Keep Event
          </Button>
          <Button colorScheme="red" variant="outline" mr={3} onClick={setEventStatus} isLoading={loading}>
            Confirm, Cancel Event
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
CancelEventModal.propTypes = {
  eventId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCompleted: PropTypes.func.isRequired,
};

const EventStatusCard = ({ event, currentUser }) => {
  const history = useHistory();
  const updateCache = useUpdateEventsByUserCache(currentUser.id);
  const updateEventCache = useUpdateActiveEventsCache();
  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const onCancel = () => {
    updateCache(event, 'remove');
    updateEventCache(event.id);
    history.push('/events');
  };

  const [publishEvent, { loading }] = useMutation(
    SET_EVENT_STATUS, {
      variables: {
        id: event.id,
        status: 'active',
      },
      onCompleted: () => {
        toast({
          title: 'Event published successfully!',
          status: 'success',
          isClosable: true,
          position: 'top',
        });
      },
    },
  );

  return (
    <GridItem gridColumn="1 / span 2">
      <Card imgSrc={eventStatusIcon} title="Event Status">
        <HStack width="100%">
          <Button variant="outline" colorScheme="red" flexGrow={1} onClick={onOpen} isDisabled={loading}>
            Cancel Event
          </Button>
          {event.status === 'draft' && currentUser.fetchRole.id === '1' && (
            <Button variant="outline" flexGrow={1} onClick={publishEvent} isLoading={loading}>
              Publish Event
            </Button>
          )}
        </HStack>
      </Card>
      <CancelEventModal
        eventId={event.id}
        isOpen={isOpen}
        onClose={onClose}
        onCompleted={onCancel}
      />
    </GridItem>
  );
};
EventStatusCard.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fetchRole: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export const ManageEvent = () => {
  const { currentUser } = useCurrentUser();
  const { id } = useParams();
  const eventId = parseInt(id, 10);

  const { data, loading } = useQuery(FETCH_EVENT, {
    variables: {
      id: eventId,
    },
    fetchPolicy: 'network-only',
  });
  const event = data?.fetchEventDetail;

  const { data: eventsUsersData, loading: eventsUsersLoading } = useQuery(
    FETCH_EVENTS_USERS_BY_USERS,
    {
      variables: {
        eventId,
        userId: currentUser.id,
      },
      fetchPolicy: 'network-only',
      skip: !currentUser,
    },
  );

  useHeaderComponentsMutation({
    title: 'MANAGE EVENT',
    backLink: '/events',
    components: [{ key: EVENT_TITLE, value: event?.title }],
  });

  if (loading || eventsUsersLoading) {
    return (
      <Flex
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Spinner size="xl" />
      </Flex>
    );
  }

  const judgeEventsUser = eventsUsersData.fetchEventsUsersByUser?.filter((eu) => eu.fetchRole.title === 'Judge')?.[0];

  return (
    <Box marginBlock={2}>
      <Alert status="info" alignItems="baseline">
        <Text>
          Need to go back to the old Admin page?
        </Text>
        <Button
          variant="ghost"
          onClick={() => {
            window.localStorage.setItem('useOldEventPage', true);
            window.location.reload();
          }}
          paddingInline={2}
          paddingBlock={1}
          marginInline={1}
          marginBlock={0}
          fontWeight="light"
        >
          Click Here!
        </Button>
      </Alert>
      <Box
        display={{ base: 'flex', lg: 'grid' }}
        gridTemplateColumns="repeat(2, 1fr)"
        gap={4}
        width="100%"
        justifyContent="center"
        flexDirection="column"
        marginBlock={4}
      >
        <QuickUserLookup eventId={eventId} />

        <UsersCard event={event} />
        <ReportsCard event={event} />
        <VStack gap={2} margin={0}>
          <RequestsCard event={event} />
          <ScorekeepingCard event={event} />
        </VStack>
        <JudgingCard eventId={eventId} judgeEventsUser={judgeEventsUser} />
        <ConfigCard event={event} />
        <AppConfigCard event={event} />
        <EventStatusCard
          currentUser={currentUser}
          event={event}
        />
      </Box>
    </Box>
  );
};
