import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { isEmpty, getOr, get } from 'lodash/fp';
import { isPast } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import {
  Table,
  Row,
  Col,
  Badge,
  Container,
} from 'reactstrap';
import { CustomRadioButton } from '../../Components/Auth/Layout';
import useCurrentUser from '../../Hooks/userCurrentUser';
import {
  MANAGE_MY_COMPETITIONS,
  FETCH_EVENT_HEADER,
} from '../../GraphQL/Queries';
import { UPLOAD_COMPETITION_SUBMISSION } from '../../GraphQL/Mutations';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import Loader from '../../Components/Loader';
import GenericAlert from '../../Components/GenericAlert';

const DISPLAY_DATE_FORMAT = 'MM/dd/yyyy hh:mm aa';
const USER_LONG_TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
const stringifyDate = (date) => format(utcToZonedTime(date, USER_LONG_TZ), DISPLAY_DATE_FORMAT);

const TitleBadge = ({ existingAttachment }) => {
  if (existingAttachment) {
    return (
      <Badge
        color="success"
        pill
      >
        Submitted
      </Badge>
    );
  }
  return (
    <Badge
      color="warning"
      pill
    >
      Missing submission
    </Badge>
  );
};

const FileUpload = ({ member, submissionsClosed, refetch }) => {
  const [isFile, setIsFile] = useState(true);
  const [isRefetching, setIsRefetching] = useState(false);
  const [link, setLink] = useState('');

  const hasSubmission = member.fetchEventCompetition?.fetchCompetition?.onlineSubmission;

  const extractError = useGraphQLErrorExtractor();
  const [success, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [uploadCompetitionSubmission, { loading }] = useMutation(
    UPLOAD_COMPETITION_SUBMISSION,
    {
      onCompleted: () => {
        setSuccess(true);
        setErrorMsg('');

        setIsRefetching(true);
        refetch().then(() => setIsRefetching(false));
      },
      onError: (err) => {
        setErrorMsg(extractError(err));
      },
    },
  );

  const submitFiles = (memberId, evt) => {
    const file = evt.target.files[0];
    const filesize = ((file.size / 1024) / 1024).toFixed(4); // MB
    if (filesize > 20) {
      setErrorMsg('File size too large! Please upload a file smaller than 20MB');
      return;
    }
    uploadCompetitionSubmission({
      variables: {
        file,
        memberId,
      },
    });
  };
  const saveExternalLink = (memberId) => {
    uploadCompetitionSubmission({
      variables: {
        externalLink: link,
        memberId,
      },
    });
  };
  const changeType = (type) => {
    setIsFile(type);
    setLink('');
  };

  const existingAttachment = member.fetchEventCompetitionAttachment[0];
  const compName = get('fetchEventCompetition.title', member);

  if (!hasSubmission) {
    return (
      <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
        <div>
          <h3>{compName}</h3>
        </div>
        <div>
          No Submission required
        </div>
      </div>
    );
  }

  return (
    <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <div>
        <TitleBadge existingAttachment={existingAttachment} />
        <h3>{compName}</h3>
        {
          existingAttachment?.attachmentName
            ? (
              <div>
                <Row>
                  <Col className="col-xs-12 col-sm-12 col-md-8 col-lg-6">
                    <Table
                      bordered
                      responsive>
                      <thead>
                        <tr>
                          <th>
                            Previously Uploaded
                          </th>
                          <th>
                            {' '}
                            <a href={existingAttachment.attachmentUrl} target="_blank" rel="noopener noreferrer">
                              {existingAttachment.attachmentName}
                            </a>
                          </th>
                          {success ? <th><Badge color="success">
                            File updated!
                          </Badge> </th> :  errorMsg !== ''?  <th><Badge
          color="warning"
          pill
        >
          Previous file will be used!
        </Badge></th> : null}
                        </tr>
                      </thead>
                    </Table>
                  </Col>
                </Row>
              </div>
            )
            : ''
        }
      </div>

      {!submissionsClosed
        ? (
          <>
            <div>
              Type of submission:
              {' '}
              <div>
                <CustomRadioButton>
                  <label className="container" htmlFor={"file_for_" + compName}>
                    <input type="radio" value="present" name={"file_for_" + compName} id={"file_for_" + compName} checked={isFile} onChange={() => changeType(true)} />
                    {' '}
                    File
                    <span className="checkmark" />
                  </label>
                </CustomRadioButton>
                <CustomRadioButton>
                  <label className="container" htmlFor={"url_for_" + compName}>
                    <input type="radio" value="absent" name={"url_for_" + compName} id={"url_for_" + compName} checked={!isFile} onChange={() => changeType(false)} />
                    {' '}
                    External URL
                    <span className="checkmark" />
                  </label>
                </CustomRadioButton>
              </div>

              {isFile ? (
                <>
                  Attach a file for your competition:
                  <div>

                    <input
                      key="file-input"
                      type="file"
                      accept="image/*,.doc,.docx,.pdf,.zip"
                      onChange={(evt) => submitFiles(member.id, evt)}
                    />
                    <div style={{ fontStyle: 'italic' }}>Files have a maximum size of 20MB. For larger files (ex. short film submissions), upload a link to an unlisted youtube video.</div>
                  </div>
                </>
              ) : (
                <>
                  Submit a link for your submission:
                  <div>
                    <input
                      key="link-input"
                      type="text"
                      onChange={(e) => setLink(e.target.value)}
                      value={link}
                    />
                    <button type="button" onClick={() => saveExternalLink(member.id)}>Save</button>
                  </div>
                </>
              )
              }
            </div>

            <div>
              {(loading || isRefetching)
                ? (
                  <>
                    <GenericAlert color="warning">Uploading. Please wait...</GenericAlert>
                    <Loader />
                  </>
                ) : success
                  ? <GenericAlert color="success">Upload successful!</GenericAlert>
                  : (errorMsg !== '') ? <GenericAlert color="danger">{errorMsg}</GenericAlert> : null}
            </div>
          </>
        ) : (
          <div>Submissions are now closed!</div>
        )}
    </div>
  );
};

const ManageMyCompetitions = () => {
  const { currentUser } = useCurrentUser(true);
  const { id } = useParams();
  const [manageMyCompetitions, { data, refetch }] = useLazyQuery(MANAGE_MY_COMPETITIONS, {
    fetchPolicy: 'network-only',
  });

  const [fetchEventDetail, { data: dataEvent }] = useLazyQuery(
    FETCH_EVENT_HEADER,
  );
  const event = getOr({}, 'fetchEventDetail', dataEvent);

  const myCompetitions = getOr([], 'manageMyCompetitions', data);

  useHeaderComponentsMutation({
    title: 'COMPETITION SUBMISSIONS',
    backLink: `/event-manage/${id}`,
    components: [{ key: EVENT_TITLE, value: event.title }],
  });

  useEffect(() => {
    if (currentUser && id) {
      fetchEventDetail({
        variables: {
          id: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, fetchEventDetail, id]);

  const fetchManageMyCompetitions = useCallback(() => {
    if (!isEmpty(get('fetchSchools', currentUser))) {
      manageMyCompetitions({
        variables: {
          eventId: parseInt(id, 10),
          userId: parseInt(currentUser?.id, 10),
        },
      });
    }
  }, [currentUser, manageMyCompetitions, id]);

  useEffect(() => {
    fetchManageMyCompetitions();
  }, [fetchManageMyCompetitions]);

  const submissionsClosed = isPast(utcToZonedTime(event.submissionDeadline, USER_LONG_TZ));
  // console.log(event.submissionDeadline);
  // console.log(utcToZonedTime(event.submissionDeadline, USER_LONG_TZ));
  // console.log(isPast(new Date(event.submissionDeadline)));

  return (
    <>
      <Container className="px-0 pt-3 ml-0">
        <Row>
          <Col>
            <h3>Your Competition Submissions</h3>
          </Col>
        </Row>
        {submissionsClosed && (
          <Row>
            <Col>
              Submissions have now closed! (submission deadline was
              {` ${stringifyDate(event.submissionDeadline)}`}
              )
            </Col>
          </Row>
        )}
      </Container>

      {myCompetitions.map((member) => (
        <FileUpload
          key={member.id}
          member={member}
          submissionsClosed={submissionsClosed}
          refetch={refetch}
        />
      ))}
    </>
  );
};

export default ManageMyCompetitions;
