import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { getOr, get } from 'lodash/fp';
import {
  FontBold,
} from '../../Components/Auth/Layout';
import {
  CATEGORY_WITH_EVENT_COMPETITIONS,
  COMPETITION_STATS,
  FETCH_EVENT_HEADER,
} from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useCurrentUser from '../../Hooks/userCurrentUser';
import List from '../../Components/List/Index';
import Loader from '../../Components/Loader';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import { CompetitionReportExport } from './CompetitionReportExport';

const CompetitionsByEvent = () => {
  const {
    currentUser,
  } = useCurrentUser(true);
  const { id } = useParams();

  const { data, loading } = useQuery(FETCH_EVENT_HEADER, {
    variables: {
      id: parseInt(id, 10),
    },
    skip: !currentUser,
  });
  const event = getOr({}, 'fetchEventDetail', data);

  const { data: count } = useQuery(
    COMPETITION_STATS,
    {
      variables: { eventId: parseInt(id, 10) },
    },
  );
  const studentsAndCompetitionsCount = getOr({}, 'competitionStats', count);

  const { data: categoriesData, loading: competitionsLoading } = useQuery(
    CATEGORY_WITH_EVENT_COMPETITIONS, {
      variables: {
        eventId: parseInt(id, 10),
      },
      skip: !currentUser,
    },
  );
  const categoryCompetitions = categoriesData?.categoryWithEventCompetitions;

  useHeaderComponentsMutation({
    title: 'Competitions REPORTS',
    backLink: `/event-manage/${id}`,
    components: [{ key: EVENT_TITLE, value: event.title }],
  });


  if (loading || competitionsLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="row mt-4">
        <div className="col-12 mx-auto px-4">
          <div className="row">
            <div className="col-6">
              <p className="mt-4 text-center">
                {get('student', studentsAndCompetitionsCount)}
              </p>
              <FontBold>
                <p className="mt-4 text-center">
                  Students
                </p>
              </FontBold>
            </div>
            <div className="col-6">
              <p className="mt-4 text-center">
                {get('competition', studentsAndCompetitionsCount)}
              </p>
              <FontBold>
                <p className="mt-4 text-center">
                  Competitions
                </p>
              </FontBold>
            </div>
          </div>
          <div className="pt-3 mb-2">
            <CompetitionReportExport
              categories={categoryCompetitions}
              filename={`${event.title} Competitions`}
              includeRegionColumn={event.qualifiedAttendingPreviousEvent}
            />
          </div>

          {categoryCompetitions && categoryCompetitions.map((obj) => (
            <>
              <FontBold>
                {get('title', obj)}
              </FontBold>
              <List
                data={get('eventCompetitions', obj)}
                displayAttribute="title"
                redirectLink={`events/${id}/competitions`}
                addNewLink=""
                addNewText=""
                addOperationRequired={false}
                membersCount="true"
                paymentEvent={id}
              />

            </>
          ))}
        </div>
      </div>
    </>
  );
};
export default CompetitionsByEvent;
