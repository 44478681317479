import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react';

import { useMarkAsPaidMutation } from './utils';

export const MarkAsPaidModal = ({
  isOpen,
  onClose,
  eventId,
  userId,
  userName,
}) => {
  const [markAsPaid, { loading }] = useMarkAsPaidMutation(onClose);
  const onConfirm = () => {
    markAsPaid({
      eventId,
      userId,
      userName,
    });
  };

  const bodyText = `Are you sure you want to mark ${userName} as paid?`;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Mark As Paid</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {bodyText}
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onConfirm} isLoading={loading}>
            Confirm
          </Button>
          <Button colorScheme="gray" variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

MarkAsPaidModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  userName: PropTypes.string.isRequired,
};
