import React from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/react-hooks';

import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';

export const REMOVE_EVENT_USER = gql`
  mutation cancelRegistrationOption($eventUserId: ID!) {
    cancelRegistrationOption(input: {
      eventsUserId: $eventUserId,
      status: 3,
    }) {
      eventsUser {
        id
        status
      }
    }
  }
`;


export const CancelRegistrationModal = ({
  isOpen,
  onClose,
  eventUserId,
  userName,
  hasPaid,
  refetchQueries,
}) => {
  const toast = useToast();
  const [cancelRegistration, { loading }] = useMutation(REMOVE_EVENT_USER, {
    variables: {
      eventUserId,
    },
    onCompleted: () => {
      toast({
        title: `Successfully canceled ${userName}'s registration`,
        status: 'success',
        isClosable: true,
        position: 'top',
      });
      onClose();
    },
    refetchQueries,
  });

  const bodyText = `Are you sure you want to cancel ${userName}'s registration?`;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Cancel Registration</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack gap={4}>
            {hasPaid && (
            <Alert status="error" borderRadius={4}>
              <AlertIcon />
              <VStack alignItems="start">
                <AlertTitle>
                  {`${userName} has already paid!`}
                </AlertTitle>
                <AlertDescription>
                Their payment will not be refunded if you remove them from this event.
                </AlertDescription>
              </VStack>
            </Alert>
            )}
            <Text>{bodyText}</Text>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={cancelRegistration} isLoading={loading}>
            Remove from Event
          </Button>
          <Button colorScheme="gray" variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

CancelRegistrationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  eventUserId: PropTypes.number.isRequired,
  userName: PropTypes.string.isRequired,
  hasPaid: PropTypes.bool.isRequired,
  refetchQueries: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};
