import React from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/react-hooks';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';

const REASSIGN_CAPTAIN = gql`
  mutation reassignCaptain
  ($schoolId: Int!, $eventId: Int!, $eventsUserId: Int!) {
    reassignCaptain(input: {
      schoolId: $schoolId
      eventId: $eventId,
      eventsUserId: $eventsUserId
    }) {
      eventsUser {
        id 
        roleId
        userId
        schoolId
        eventId
        status
      }
    }
  }
`;

export const AssignCaptainModal = ({
  isOpen,
  onClose,
  eventId,
  schoolId,
  eventUserId,
  userName,
}) => {
  const toast = useToast();
  const [assignAsCaptain, { loading }] = useMutation(REASSIGN_CAPTAIN);
  const onConfirm = () => {
    assignAsCaptain({
      variables: {
        eventId,
        schoolId,
        eventsUserId: eventUserId,
      },
    }).then(() => {
      toast({
        title: `Successfully promoted ${userName} to captain.`,
        status: 'success',
        isClosable: true,
        position: 'top',
      });
    });
  };

  const bodyText = `Are you sure you want to assign ${userName} as captain?`;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Assign as Captain</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {bodyText}
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onConfirm} isLoading={loading}>
            Confirm
          </Button>
          <Button colorScheme="gray" variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

AssignCaptainModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
  schoolId: PropTypes.number.isRequired,
  eventUserId: PropTypes.number.isRequired,
  userName: PropTypes.string.isRequired,
};
