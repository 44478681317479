/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import {
  Modal, ModalBody, Button,
} from 'reactstrap';

import styles from '../../Components/Auth/ImageUploadStyles';
import {
  DottedBorder,
} from '../../Components/Auth/Layout';
import GenericAlert from '../../Components/GenericAlert';
import { FILE_SIZE_LIMIT } from '../../Constants/AppConstants';

const ImageUpload = ({
  onUpload,
  existingImageUrl,
  isRequired,
  additionalText,
}) => {
  const [uploadError, setUploadError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  const [imageFile, setImageFile] = useState(null);
  const onDelete = () => setImageFile(null);
  const onDrop = ([file]) => {
    setImageFile(file);
    setUploadError();
  };

  let errorMessage = null;

  if (imageFile) {
    if (imageFile.size > FILE_SIZE_LIMIT) {
      errorMessage = 'Maximum upload size is 10MB.';
    }
    if (!imageFile.type.match('image/png') && !imageFile.type.match('image/jpeg')) {
      errorMessage = 'You can only upload picture files in png or jpeg format.';
    }
  }
  if (uploadError) {
    errorMessage = uploadError;
  }

  const imageIsValid = errorMessage !== null;

  const onClickUpload = () => {
    setIsLoading(true);
    onUpload(imageFile)
      .then(() => {
        setModalIsOpen(false);
        setImageFile(null);
      })
      .catch((err) => setUploadError(err))
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <div className="justify-content-center">
        <div className="image-upload-holder" role="button" aria-hidden onClick={() => setModalIsOpen(true)}>
          {existingImageUrl ? (
            <img style={styles.profileImage} src={existingImageUrl} alt="User Avatar" className="circle-default" />
          ) : (
            <div className="circle-default" />
          )}
          <Modal isOpen={modalIsOpen} toggle={toggleModal} backdrop="static">
            <ModalBody>
              {errorMessage ? <GenericAlert>{errorMessage}</GenericAlert> : null}
              <div style={styles.uploadContainer}>
                <div style={styles.header}>
                  <p className="upload-header">Upload image</p>
                  <button type="button" style={styles.crossBtn} onClick={() => setModalIsOpen(false)}>x</button>
                </div>
                {imageFile
                  ? (
                    <div style={styles.imageUploadBox}>
                      <div className="image-align d-flex align-items-center justify-content-center" style={styles.dropFileImage}>
                        <div className="image-info d-flex align-items-center justify-content-center">
                          <i style={styles.deleteImageIcon} onMouseDown={onDelete} onTouchEnd={onDelete} className="fas fa-times-circle delete-image" />
                          <span className="d-flex flex-nowrap">
                            <i style={styles.fileIcon} className="fas fa-file-image" />
                            {imageFile.name}
                          </span>
                        </div>
                        <Button onClick={onClickUpload} color="primary" style={styles.uploadBtn} disabled={imageIsValid || isLoading}>
                          <i className="fas fa-file-upload" style={styles.uploadBtnIcon} />
                          {isLoading ? 'Uploading...' : 'Upload'}
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <Dropzone onDrop={onDrop} acceptedFiles="audio/*">
                      {({ getRootProps, getInputProps }) => (
                        <div className="drop-file" {...getRootProps()}>
                          <input {...getInputProps()} accept="image/png,image/jpeg" />
                          <i className="fal fa-file-plus file-plus-icon" />
                          <DottedBorder className=" pl-2 pt-2">
                            <p className="select-file"> Select a File to Upload</p>
                            <p className="font-14">or drag and drop the file here</p>
                          </DottedBorder>
                        </div>
                      )}
                    </Dropzone>
                  )}
              </div>
            </ModalBody>
          </Modal>

        </div>
        <button type="button" style={{ width: '100%' }} className="btn avatar-btn mx-auto" aria-hidden onClick={() => setModalIsOpen(true)}>
          Upload Image
          {isRequired && (<span className="text-danger"> *</span>)}
          {additionalText && (
            <p style={{ color: '#A6A6A6' }}>
              {additionalText}
            </p>
          )}
        </button>
      </div>
    </>
  );
};

ImageUpload.propTypes = {
  onUpload: PropTypes.func.isRequired,
  existingImageUrl: PropTypes.string,
  isRequired: PropTypes.bool,
  additionalText: PropTypes.string,
};

ImageUpload.defaultProps = {
  existingImageUrl: '',
  isRequired: false,
  additionalText: '',
};

export default ImageUpload;
