import React from 'react';
import PropTypes from 'prop-types';
import { Link as ReactRouterLink } from 'react-router-dom';
import {
  Box,
  GridItem,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';

import judgingIcon from '../../../Images/event-manage-v2/judging.png';

import { Card } from './Card';
import { isRoundFinalized } from '../../Scores/CompetitionScores';


const CompRow = ({ eventCompetition, round, eventId }) => {
  const name = eventCompetition.rounds.length === 1 ? eventCompetition.title : round.name;
  return (
    <GridItem>
      <Link
        as={ReactRouterLink}
        to={`/events/${eventId}/judging/${eventCompetition.id}/rounds/${round.id}`}
        color="blue.500"
        textDecoration="underline"
      >
        {name}
      </Link>
    </GridItem>
  );
};
CompRow.propTypes = {
  eventCompetition: PropTypes.shape({}).isRequired,
  round: PropTypes.shape({}).isRequired,
  eventId: PropTypes.number.isRequired,
};

const CompsGrid = ({ children }) => (
  <Box
    display={{ base: 'flex', lg: 'grid' }}
    gridTemplateColumns="repeat(2, 1fr)"
    gap={2}
    width="100%"
    justifyContent="center"
    flexDirection="column"
    marginBlock={2}
  >
    {children}
  </Box>
);
CompsGrid.propTypes = {
  children: PropTypes.node.isRequired,
};

export const JudgingCard = ({ eventId, judgeEventsUser }) => {
  if (!judgeEventsUser) {
    return null;
  }

  const hasFinalizedRounds = judgeEventsUser?.competitionsToJudge
    .flatMap((ec) => ec.rounds)
    .filter((r) => isRoundFinalized(r))
    .length > 0;

  return (
    <Box
      gridColumn="1 / 3"
    >
      <Card imgSrc={judgingIcon} title="Judging">
        <VStack width="100%" alignItems="start">
          <Text>Competitions to Judge:</Text>
          <CompsGrid>
            {judgeEventsUser?.competitionsToJudge.flatMap((ec) => ec.rounds
              .filter((r) => !isRoundFinalized(r))
              .map((r) => <CompRow eventCompetition={ec} round={r} eventId={eventId} />)
            )}
          </CompsGrid>
          {hasFinalizedRounds && (
            <details style={{ marginTop: '1.5rem' }}>
              <summary>See completed rounds:</summary>
              <CompsGrid>
                {judgeEventsUser?.competitionsToJudge.flatMap((ec) => ec.rounds
                  .filter((r) => isRoundFinalized(r))
                  .map((r) => <CompRow eventCompetition={ec} round={r} eventId={eventId} />)
                )}
              </CompsGrid>
            </details>
          )}
        </VStack>
      </Card>
    </Box>
  );
};
JudgingCard.propTypes = {
  eventId: PropTypes.number.isRequired,
  judgeEventsUser: PropTypes.shape({}).isRequired,
};
