import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import { get, getOr } from 'lodash/fp';
import { RedirectButton } from '../../Components/Form';
import {
  FontBold,
} from '../../Components/Auth/Layout';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import Loader from '../../Components/Loader';
import Search from '../../Components/Search/Index';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import { FETCH_EVENT_GUESTS } from '../../GraphQL/Queries';
import useCurrentUser from '../../Hooks/userCurrentUser';
import useUserSearch from '../../Hooks/useUserSearch';
import userDefaultImage from '../../Images/icons/userIcon.png';
import UserList from '../../Components/UserList/Index';
import RIGHT_ARROW from '../../Images/icons/right-color-arrow.png';

const Guests = () => {
  const {
    currentUser,
  } = useCurrentUser(true);
  const searchList = useUserSearch();
  const { id } = useParams();
  const [searchGuests, setSearchGuests] = useState([]);

  const [fetchStudents, { data: guestsData, loading: guestsLoading }] = useLazyQuery(
    FETCH_EVENT_GUESTS, {
      onCompleted: (data) => {
        setSearchGuests(data.eventGuests);
      },
    },
  );
  const guests = getOr([], 'eventGuests', guestsData);
  useEffect(() => {
    if (currentUser) {
      fetchStudents({
        variables: {
          eventId: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, fetchStudents, id]);

  const searchStudent = (e) => {
    if (e.target.value === '') {
      setSearchGuests(guests);
    } else {
      setSearchGuests(
        searchList(searchGuests, e.target.value),
      );
    }
  };
  useHeaderComponentsMutation({
    title: 'GUESTS',
    backLink: `/event-manage/${id}`,
    components: [{ key: EVENT_TITLE, value: get('fetchEvent.title', guests[0]) }],
  });

  return (
    <>
      <div className="mt-4" />
      <div className="row">
        <div className="col-md-6 col-12">
          <RedirectButton backgroundcolor="#F4AB37" fontColor="#FFF" border="none" addNewText="INVITE USER" addNewLink={`/event-manage/${id}/invite-existing-user/guest`} />
        </div>
        <div className="col-md-6 col-12">
          <RedirectButton backgroundcolor="#FFF" fontColor="rgba(244, 171, 55, 1)" border="2px solid #f3AA44" addNewText="INVITE NEW USER" addNewLink={`/event-manage/${id}/invite-new-user/guest`} />
        </div>
      </div>
      <div className="mt-4" />
      <Search
        placeholder="Search"
        onChange={searchStudent}
      />
      <div className="row">
        <div className="col-12 mx-auto px-4">
          <FontBold>
            <p className="mt-4">
              Guests(
              {searchGuests.length}
)
            </p>
          </FontBold>
        </div>
      </div>
      {guestsLoading ? (
        <Loader />
      ) : searchGuests.map((eventUser) => (
        <>
          <div key={eventUser.id}>
            <UserList
              user={get('fetchUser', eventUser)}
              imageAttached={userDefaultImage}
              arrowIcon={RIGHT_ARROW}
              redirectLink={`/event-manage/${id}/event-user-profile/${get('id', get('fetchUser', eventUser))}`}
            />
          </div>
        </>
      ))}
    </>
  );
};
export default Guests;
