import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import Form, { Input, PermissionButton, Checkbox } from '../../Components/Form';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import { FETCH_ALL_BALLOTS } from '../../GraphQL/Queries';
import { getOr } from 'lodash/fp';

const CompetitionForm = ({
  params, onSubmit, onChange, loading, setParams, formType, currentUser,
}) => {
  const checkPermission = useUserPermissionChecker(currentUser);
  const [fetchAllBallots, { data }] = useLazyQuery(FETCH_ALL_BALLOTS);
  const ballots = getOr([], 'fetchAllBallots', data);

  useEffect(() => {
    if (currentUser) {
      fetchAllBallots();
    }
  }, [currentUser, fetchAllBallots]);

  return (
    <Form onSubmit={onSubmit}>
      <Input
        title="Title"
        label="Title"
        name="title"
        id="title"
        placeholder="Title"
        required
        onChange={onChange}
        params={params}
        pattern=".*\S+.*"
      />
      <Checkbox
        title="Matchup Competition"
        id="matchup"
        name="matchup"
        params={params}
        onChange={onChange}
        text="Matchup Competition"
        setParams={setParams}
      />
      <Checkbox
        title="Group Competition"
        id="group"
        name="group"
        params={params}
        onChange={onChange}
        text="Group Competition"
        setParams={setParams}
      />
      <Checkbox
        title="Enable Gender"
        id="genderEnable"
        name="genderEnable"
        params={params}
        onChange={onChange}
        text="Enable Gender"
        setParams={setParams}
      />

      <Checkbox
        title="Online Submission Required"
        id="onlineSubmission"
        name="onlineSubmission"
        params={params}
        onChange={onChange}
        text="Online Submission Required"
        setParams={setParams}
      />
      <Checkbox
        title="Requires Additional Competition"
        id="requiresAdditionalCompetition"
        name="requiresAdditionalCompetition"
        params={params}
        onChange={onChange}
        text="Requires Additional Competition"
        setParams={setParams}
      />
      <p style={{ paddingTop: '1rem' }}>Details</p>
      <Input
        title="Description"
        label="Description"
        name="description"
        id="description"
        placeholder="Description"
        onChange={onChange}
        type="textarea"
        params={params}
        withFormGroup
      />
      <p>You need help text here to explain what this is to people</p>
      <div className="row">
        <div className="col-6">
          <Input
            title="Min Team Size"
            label="Min Team Size"
            name="minTeam"
            id="minTeam"
            placeholder="Min Team Size"
            required
            onChange={onChange}
            params={params}
            withFormGroup
            type="number"
          />
        </div>
        <div className="col-6">
          <Input
            title="Max Team Size"
            label="Max Team Size"
            name="maxTeam"
            id="maxTeam"
            placeholder="Max Team Size"
            required
            onChange={onChange}
            params={params}
            withFormGroup
            type="number"
          />
        </div>
      </div>

      <Input
        title="Maximum Number of Submissions"
        label="Maximum Number of Submissions"
        name="maxSubmission"
        id="maxSubmission"
        placeholder="Maximum Number of Submissions"
        required
        onChange={onChange}
        params={params}
        withFormGroup
        type="number"
      />

      <Input
        title="Ballot"
        label="Ballot"
        name="ballotId"
        type="select"
        params={params}
        id="ballotId"
        required
        onChange={onChange}>
        <option value="" disabled>Choose a Ballot</option>
        {ballots && ballots.map((obj) => (
          <option key={obj.id} value={obj.id}>{obj.title}</option>
        ))}
      </Input>

      {formType === 'new'
        ? <PermissionButton loading={loading} backgroundcolor="#F4AB37" fontColor="#FFF" border="none" htmltype="submit" checkPermission={(checkPermission('Add Competition'))}>SUBMIT</PermissionButton>
        : <PermissionButton loading={loading} backgroundcolor="#F4AB37" fontColor="#FFF" border="none" htmltype="submit" checkPermission={(checkPermission('Update Competition'))}>SUBMIT</PermissionButton>}
    </Form>
  );
};

CompetitionForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  params: PropTypes.instanceOf(Object).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  setParams: PropTypes.func.isRequired,
  formType: PropTypes.string.isRequired,
  currentUser: PropTypes.instanceOf(Object).isRequired,
};

CompetitionForm.defaultProps = {
  loading: false,
};

export default CompetitionForm;
