import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash/fp';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import Form, { Input, Button, Title } from '../../Components/Form';
import {
  EventImageUpload, EventUploadedImage, DatePickerInput, TitleC,
} from '../../Components/Auth/Layout';
import 'react-datepicker/dist/react-datepicker.css';
import useCurrentUser from '../../Hooks/userCurrentUser';

const DISPLAY_DATE_FORMAT = 'MM/dd/yyyy hh:mm aa';
const USER_LONG_TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
const USER_TZ = format(new Date(), 'z');

const EventForm = ({
  params, onSubmit, onChange, loading, regionData, eventObj, setParams,
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const submissionDeadline = params.submissionDeadline ? utcToZonedTime(params.submissionDeadline, USER_LONG_TZ) : '';

  const {
    currentUser,
  } = useCurrentUser(true);

  const updateStartDate = (date) => {
    if (currentUser) {
      setStartDate(date);
      setParams({
        ...params,
        startDate: moment(date).format('YYYY-MM-DD'),
      });
    }
  };
  const updateEndDate = (date) => {
    if (currentUser) {
      setEndDate(date);
      setParams({
        ...params,
        endDate: moment(date).format('YYYY-MM-DD'),
      });
    }
  };
  const updateSubmissionDeadline = (date) => {
    const utcDate = zonedTimeToUtc(date, USER_LONG_TZ);
    setParams({
      ...params,
      submissionDeadline: utcDate.toISOString(),
    });
  };

  return (
    <Form onSubmit={onSubmit}>
      <Input
        title="Event Name"
        name="title"
        id="title"
        placeholder="Event Name"
        required
        onChange={onChange}
        params={params}
      />
      <Input title="Default Region" name="regionId" type="select" params={params} id="regionId" required onChange={onChange}>
        <option value="" disabled>Regions</option>
        {regionData && regionData.map((obj) => (
          <option key={obj.id} value={obj.id}>{obj.name}</option>
        ))}
      </Input>
      <Input
        title="Event Description"
        name="label"
        id="label"
        placeholder="Event Description"
        required
        onChange={onChange}
        params={params}
        type="textarea"
      />
      <Title>
        <p className="mt-4">
          Banner Image
          <span style={{ color: 'red' }}> *</span>
        </p>
      </Title>
      <EventImageUpload>
        <input
          style={{ width: '100%' }}
          title="Event Cover Photo"
          label="Event Cover Photo"
          name="attachment"
          id="attachment"
          placeholder=""
          type="file"
          accept="image/*"
          onChange={(e) => onChange('attachment', e.target.files[0])}
        />
      </EventImageUpload>
      {get('coverImageUrl', eventObj) && (!params.attachment)
        ? (
          <div>
            Already uploaded:
            <EventUploadedImage>
              <img src={eventObj.coverImageUrl} alt="event" />
            </EventUploadedImage>
          </div>
        )
        : null}
      <p className="mt-3">
        <TitleC>
          Event Start Date
          {' '}
          <span className="text-danger">*</span>
        </TitleC>
      </p>
      <DatePickerInput>
        <DatePicker
          value={params.startDate}
          selected={startDate}
          onChange={(date) => updateStartDate(date)}
          dateFormat="DD/MM/YYYY"
          required
          placeholderText="mm/dd/yyyy"
        />
      </DatePickerInput>
      <p className="mt-3">
        <TitleC>
          Event End Date
          {' '}
          <span className="text-danger">*</span>
        </TitleC>
      </p>
      <DatePickerInput>
        <DatePicker
          value={params.endDate}
          selected={endDate}
          onChange={(date) => updateEndDate(date)}
          dateFormat="DD/MM/YYYY"
          required
          placeholderText="mm/dd/yyyy"
        />
      </DatePickerInput>
      <Input
        title="Event Fee"
        name="fee"
        id="fee"
        placeholder="Event Fee"
        required
        onChange={onChange}
        params={params}
        type="number"
      />
      <p className="mt-3">
        <TitleC>
          {`Submission Deadline (in ${USER_TZ})`}
        </TitleC>
      </p>
      <DatePickerInput>
        <DatePicker
          selected={submissionDeadline}
          onChange={(date) => updateSubmissionDeadline(date)}
          dateFormat={DISPLAY_DATE_FORMAT}
          required
          placeholderText="mm/dd/yyyy"
          timeIntervals={30}
          showTimeSelect
        />
      </DatePickerInput>
      <Input
        title="Event Location"
        id="location"
        name="location"
        placeholder="Event Location"
        onChange={onChange}
        params={params}
        withFormGroup
        required
      />
      <Input
        title="Financial Aid Form Link"
        label="Financial Aid Form Link"
        name="financialAidFormLink"
        id="financialAidFormLink"
        placeholder="Financial Aid Form Link"
        onChange={onChange}
        params={params}
        withFormGroup
        type="url"
        pattern="https?://.+"
      />
      <Input
        title="Discord Link"
        label="Discord Link"
        name="discordLink"
        id="discordLink"
        placeholder="Discord Link"
        onChange={onChange}
        params={params}
        withFormGroup
        type="url"
        pattern="https?://.+"
      />
      <Input
        title="Judge Application Link"
        label="Judge Application Link"
        name="judgeApplicationLink"
        id="judgeApplicationLink"
        placeholder="Judge Application Link"
        onChange={onChange}
        params={params}
        withFormGroup
        type="url"
        pattern="https?://.+"
      />
      <Input
        title="Volunteer Application Link (Https Link)"
        label="Volunteer Application Link"
        name="volunteerApplicationLink"
        id="volunteerApplicationLink"
        placeholder="Volunteer Application Link"
        onChange={onChange}
        params={params}
        withFormGroup
        type="url"
        pattern="https?://.+"
      />
      <Input
        title="Sponsor Application Link"
        label="Sponsor Application Link"
        name="sponsorApplicationLink"
        id="sponsorApplicationLink"
        placeholder="Sponsor Application Link"
        onChange={onChange}
        params={params}
        withFormGroup
        type="url"
        pattern="https?://.+"
      />
      <Input
        title="Terms and Conditions"
        label="Terms and Condtitions"
        name="termsAndConditions"
        id="termsAndConditions"
        placeholder="Terms and Conditions"
        onChange={onChange}
        params={params}
        withFormGroup
        type="textarea"
        rows="10"
        maxLength="100000"
      />
      <Button backgroundcolor="#F4AB37" loading={loading} htmltype="submit">SUBMIT</Button>
    </Form>
  );
};

EventForm.propTypes = {
  params: PropTypes.instanceOf(Object).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  regionData: PropTypes.instanceOf(Array).isRequired,
  eventObj: PropTypes.instanceOf(Object),
  setParams: PropTypes.func.isRequired,
};

EventForm.defaultProps = {
  loading: false,
  eventObj: null,
};

export default EventForm;
