import React, { useState } from 'react';
import { gql } from '@apollo/client';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { useParams, Link } from 'react-router-dom';
import {
  getOr,
} from 'lodash/fp';
import {
  Button,
  HStack,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FaTimes } from 'react-icons/fa';

import {
  FETCH_EVENT,
  TEAM_CAPTAIN,
} from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import Loader from '../../Components/Loader';
import GenericAlert from '../../Components/GenericAlert';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useCurrentUser from '../../Hooks/userCurrentUser';
import { EVENT_TITLE } from '../../Constants/AppConstants';

import { Provider } from '../../Chakra';
import { CancelRegistrationModal } from './EventManageV2/Modals/CancelRegistrationModal';

const EVENT_USER = `
  id
  fetchUser {
    id
    profileImageUrl
    code
    firstname
    lastname
  }
  fetchOrder {
    id
    status
  }
`;

const FETCH_STUDENTS = gql`
  query fetchEventSchoolStudents($eventId: Int!, $schoolId: Int!){
    fetchEventSchoolStudents(eventId: $eventId, schoolId: $schoolId){
      ${EVENT_USER}
    }
  }
`;

const FETCH_COACHES = gql`
  query fetchEventSchoolCoaches($eventId: Int!, $schoolId: Int!){
    fetchEventSchoolCoaches(eventId: $eventId, schoolId: $schoolId) {
      ${EVENT_USER}
    }
  }
`;

const FETCH_GUESTS = gql`
  query searchEventGuestBySchool($eventId: Int!, $schoolId: Int!){
    searchEventGuestBySchool(eventId: $eventId, schoolId: $schoolId){
      ${EVENT_USER}
    }
  }
`;

const FETCH_TEAM = gql`
  query eventSchoolTeam($eventId: Int!, $schoolId: Int!) {
    eventSchoolTeam(eventId: $eventId, schoolId: $schoolId) {
      code
      status
      school {
        id
        name
      }
    }
  }
`;

const RemoveUsers = () => {
  const {
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);
  const { eventId, schoolId } = useParams();
  const extractError = useGraphQLErrorExtractor();
  const [showCaptainModal, setShowCaptainModal] = useState(false);
  const [eventUserToRemove, setEventUserToRemove] = useState();

  const { data, loading, error } = useQuery(
    FETCH_EVENT,
    {
      variables: { id: parseInt(eventId, 10) },
    },
  );
  const event = getOr({}, 'fetchEventDetail', data);

  const { data: teamData, loading: teamLoading } = useQuery(FETCH_TEAM, {
    variables: {
      eventId: parseInt(eventId, 10),
      schoolId: parseInt(schoolId, 10),
    },
  });
  const team = teamData?.eventSchoolTeam?.[0] ?? {};
  const teamFinalize = team.status === 'closed';

  const { data: dataCoaches, loading: loadingCoaches } = useQuery(FETCH_COACHES, {
    variables: {
      eventId: parseInt(eventId, 10),
      schoolId: parseInt(schoolId, 10),
    },
  });
  const teamCoaches = getOr([], 'fetchEventSchoolCoaches', dataCoaches);

  const { data: dataStudents, loading: loadingStudents } = useQuery(FETCH_STUDENTS, {
    variables: {
      eventId: parseInt(eventId, 10),
      schoolId: parseInt(schoolId, 10),
    },
  });
  const teamStudents = getOr([], 'fetchEventSchoolStudents', dataStudents);

  const { data: dataGuests, loading: loadingGuests } = useLazyQuery(FETCH_GUESTS, {
    variables: {
      eventId: parseInt(eventId, 10),
      schoolId: parseInt(schoolId, 10),
    },
  });
  const teamGuests = getOr([], 'searchEventGuestBySchool', dataGuests);

  const { data: dataCaptain, loading: loadingCaptain } = useQuery(TEAM_CAPTAIN, {
    variables: {
      eventId: parseInt(eventId, 10),
      schoolId: parseInt(schoolId, 10),
    },
  });

  // `fetchTeamCaptian` is intentionally misspelled, thanks legacy app
  const teamCaptain = getOr({}, 'fetchTeamCaptian', dataCaptain);

  useHeaderComponentsMutation({
    title: 'REMOVE USERS',
    backLink: `/event/${eventId}/teams/${schoolId}`,
    components: [{ key: EVENT_TITLE, value: event.title }],
  });

  if (loading || userLoading || teamLoading || loadingStudents
    || loadingCaptain || loadingCoaches || loadingGuests) {
    return <Loader />;
  }
  if (error || userError) {
    return <GenericAlert>{extractError(error || userError)}</GenericAlert>;
  }

  if (teamFinalize) {
    return (
      <h3>
        This team has already been finalized.
        Please unfinalize before attempting to remove team members.
      </h3>
    );
  }

  return (
    <Provider>
      <div className="mt-4" />
      <div className="row">
        <div className="col-12 mx-auto px-4">
          {[...teamStudents, ...teamCoaches, ...teamGuests].map((eventUser) => (
            <div key={eventUser.id}>
              <UserItem
                user={eventUser.fetchUser}
                schoolName={team.school.name}
                schoolCode={team.code}
                onRemove={() => {
                  const isOnlyUser = teamStudents.length + teamCoaches.length === 1;
                  const isTeamCaptain = eventUser.fetchUser.id === teamCaptain.fetchUser.id;
                  if (isTeamCaptain && !isOnlyUser) {
                    setShowCaptainModal(true);
                  } else {
                    setEventUserToRemove(eventUser);
                  }
                }}
              />
            </div>
          ))}
        </div>
        {showCaptainModal && (
          <IsCaptainModal
            isOpen={showCaptainModal}
            onClose={() => setShowCaptainModal(false)}
            eventId={eventId}
            schoolId={schoolId}
          />
        )}
        {!!eventUserToRemove && (
          <CancelRegistrationModal
            isOpen={!!eventUserToRemove}
            onClose={() => setEventUserToRemove(undefined)}
            eventUserId={eventUserToRemove.id}
            userName={eventUserToRemove.fetchUser.firstname}
            hasPaid={eventUserToRemove.fetchOrder?.status === 'complete'}
            refetchQueries={[
              'fetchEventSchoolStudents',
              'fetchEventSchoolCoaches',
              'searchEventGuestBySchool',
            ]}
          />
        )}
      </div>
    </Provider>
  );
};

export default RemoveUsers;

const UserItem = ({
  user,
  schoolName,
  schoolCode,
  onRemove,
}) => {
  const fullname = `${user.firstname} ${user.lastname}`;
  return (
    <HStack justifyContent="space-between" marginBlock={8}>
      <HStack>
        <Image
          src={user.profileImageUrl}
          borderRadius="100%"
          height="64px"
          width="64px"
          objectFit="cover"
          aspectRatio={1}
        />
        <VStack alignItems="start" marginInlineStart={2}>
          <Text>{fullname}</Text>
          <Text>
            {schoolCode}
  -
            {user.code}
          </Text>
          <Text>
            {schoolName}
          </Text>
        </VStack>
      </HStack>
      <IconButton
        aria-label={`Remove ${fullname}`}
        icon={<FaTimes />}
        onClick={onRemove}
        variant="ghost"
      />
    </HStack>
  );
};

const IsCaptainModal = ({
  isOpen,
  onClose,
  eventId,
  schoolId,
}) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Mark As Paid</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Text>
            This user is the team captain!
            You cannot remove the team captain while there
            are other active members of this team. Please
            either remove the remaining members first or
            reassign captain to another team member and
            then come back to remove this user.
        </Text>
      </ModalBody>

      <ModalFooter>
        <Button
          as={Link}
          to={`/event/${eventId}/teams/${schoolId}/reassign-captain`}
          mr={3}
        >
          Reassign Captain
        </Button>
        <Button colorScheme="gray" variant="outline" mr={3} onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);
