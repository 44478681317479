import { gql } from '@apollo/client';
import { useMutation } from '@apollo/react-hooks';
import { useToast } from '@chakra-ui/react';

const MARK_AS_PAID = gql`
  mutation markAsPaid($input: MarkAsPaidInput!) {
    markAsPaid(input: $input) {
      eventUser {
        id
        fetchOrder {
          id
          status
        }
      }
    }
  }
`;

export const useMarkAsPaidMutation = (onClose) => {
  const toast = useToast();

  const [markAsPaid, { loading }] = useMutation(MARK_AS_PAID);
  const onConfirm = ({ eventId, userId, userName }) => {
    markAsPaid({
      variables: {
        input: {
          eventId,
          userId,
        },
      },
    }).then(() => {
      toast({
        title: `${userName} successfully marked as paid`,
        status: 'success',
        isClosable: true,
        position: 'top',
      });
      onClose();
    }).catch((e) => {
      toast({
        title: e.graphQLErrors?.[0]?.message ?? e.message,
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    });
  };

  return [onConfirm, { loading }];
};
