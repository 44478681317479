import React, { useState, useEffect, useMemo } from 'react';
import { Collapse, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import { get } from 'lodash/fp';
import moment from 'moment';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  OrderCard,
  ImageButton,
  OrderDescriptionCard,
  PaymentDescription,
  PaymentTransaction,
} from '../../Components/Auth/Layout';
import UP_ICON from '../../Images/icons-png/carat-u-black.png';
import DOWN_ICON from '../../Images/icons-png/carat-d-black.png';
import { FETCH_REGISTRATION_OPTION_DETAIL } from '../../GraphQL/Queries';

const lineItemTitle = (lineItem) => {
  switch (lineItem.lineItemAbleType) {
    case 'Coupon': return 'Coupon Discount';
    case 'Merchandise': return lineItem.description;
    case 'RegistrationOption': return `Registration type: ${lineItem.fetchRegistrationOption.title}`;
    case 'Donation': return 'Donation';
    default: return null;
  }
};

const OrderDetail = (props) => {
  const {
    order,
    index,
    backgroundColor,
    currentUser,
    color,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const [registrationOption, setRegistrationOption] = useState({});

  const toggle = () => setIsOpen(!isOpen);

  const [fetchRegistrationOptionDetail] = useLazyQuery(
    FETCH_REGISTRATION_OPTION_DETAIL, {
      onCompleted: (data) => {
        setRegistrationOption(data.fetchRegistrationOptionDetail);
      },
    },
  );

  const regOptionLineItem = useMemo(() => order.fetchLineItems.find((li) => li.lineItemAbleType === 'RegistrationOption'), [order]);

  useEffect(() => {
    if (currentUser && order) {
      fetchRegistrationOptionDetail({
        variables: {
          id: parseInt(regOptionLineItem.lineItemAbleId, 10),
        },
      });
    }
  }, [currentUser, fetchRegistrationOptionDetail, order, regOptionLineItem]);

  return (
    <div className="pt-3" key={index}>
      <OrderCard backgroundColor={backgroundColor}>
        <div className="row">
          <div className="col-md-4 col-4">
            <h3 style={{ color }}>{moment(order.createdAt, 'YYYY-MM-DDD').format('MMMM')}</h3>
            <p style={{ fontWeight: '600', color }}>{moment(order.createdAt, 'YYYY-MM-DDD').format('DD')}</p>
          </div>
          <div className="col-md-5 col-4">
            <h4 style={{ color }}>{get('description', regOptionLineItem)}</h4>
            <p style={{ color }}>{get('title', registrationOption)}</p>
          </div>
          <div className="col-md-2 offset-md-1 col-4">
            {order.status === 'pending'
              ? (
                <h5 style={{ color: '#F6404D', marginTop: '2rem' }}>
                $
                  {parseFloat(order.amount / 100, 10).toFixed(2)}
                </h5>
              )
              : (
                <>
                  <ImageButton
                    style={{
                      backgroundColor: 'transparent', border: 'none',
                    }}
                    onClick={() => toggle()}
                  >
                    {isOpen === true
                      ? <img src={UP_ICON} alt="up" />
                      : <img src={DOWN_ICON} alt="down" />}
                  </ImageButton>
                  <h5>
                    $
                    {parseFloat(order.amount / 100, 10).toFixed(2)}
                  </h5>
                </>
              )}
          </div>
        </div>
        <Collapse isOpen={isOpen}>
          <hr />
          <OrderDescriptionCard>
            <CardBody style={{ padding: '0' }}>
              <div className="row">
                <div className="col-md-3 col-3" />
                <div className="col-md-9 col-9">
                  <PaymentDescription style={{ height: 'auto' }} textColor="#3C3C3C" marginRight="2.5rem">
                    {order && order.fetchLineItems
                      && order.fetchLineItems.sort((l1, l2) => l1.id - l2.id).map((lineItem) => (
                        <div key={lineItem.id}>
                          <p>
                            {lineItemTitle(lineItem)}
                            <span style={{ color: '#F4AB37', float: 'right' }}>
                                $
                              {parseFloat(lineItem.amount / 100).toFixed(2)}
                            </span>
                          </p>
                        </div>
                      ))}
                  </PaymentDescription>
                  <PaymentTransaction>
                    <p style={{ marginLeft: '-8rem' }}>
                      Transaction ID
                    </p>
                    <p>
                      {order.transactionId}
                    </p>
                  </PaymentTransaction>
                </div>
              </div>
            </CardBody>
          </OrderDescriptionCard>
        </Collapse>
      </OrderCard>
    </div>
  );
};

OrderDetail.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  currentUser: PropTypes.instanceOf(Object).isRequired,
  color: PropTypes.string.isRequired,
};

OrderDetail.defaultProps = {
};


export default OrderDetail;
