import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { format, utcToZonedTime } from 'date-fns-tz';
import { gql } from '@apollo/client';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import {
  Alert,
  Button,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';

import { useMarkAsPaidMutation } from './utils';

const DISPLAY_DATE_FORMAT = 'MM/dd/yyyy hh:mm aa';
const USER_LONG_TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
const formatDateStr = (date) => format(
  utcToZonedTime(new Date(date), USER_LONG_TZ), DISPLAY_DATE_FORMAT,
);

const SEARCH_COUPON = gql`
  query searchCouponInEvent($eventId: Int!, $code: String!) {
    searchCouponInEvent(eventId: $eventId, code: $code) {
      id
      code
      maxCodeUsage
      usageCount
      value
      valuePercentage
      fetchRole {
        id
        title
      }
      expirationDate
      status
    }
  }
`;

const APPLY_COUPON = gql`
  mutation applyCoupon($input: ApplyCouponInput!) {
    applyCoupon(input: $input) {
      message
      order {
        id
        status
        amount
      }
    }
  }
`;

const CouponStatus = ({ status }) => {
  switch (status) {
    case 'active':
      return <Text as="span" color="green.500" fontWeight="bold">Active</Text>;
    case 'cancelled':
      return <Text as="span" color="red.500">Cancelled</Text>;
    case 'expired':
      return <Text as="span" color="red.500">Expired</Text>;
    default: return <Text>{status}</Text>;
  }
};

const CouponInfo = ({ coupon }) => {
  const value = coupon.valuePercentage ? `${coupon.value}%` : `$${coupon.value}`;

  return (
    <VStack alignItems="start">
      <Text>
        {coupon.code}
        {' '}
        -
        {' '}
        <Text fontWeight="bold" display="inline">
          {value}
        </Text>
        {' off'}
      </Text>
      <Text>
        Role:
        {' '}
        {coupon.fetchRole.title}
      </Text>
      <Text>
        Uses:
        {' '}
        {coupon.usageCount ?? 0}
        /
        {coupon.maxCodeUsage}
      </Text>
      <Text>
        Expiry:
        {' '}
        {formatDateStr(coupon.expirationDate)}
      </Text>
      <Text>
        Status:
        {' '}
        <CouponStatus status={coupon.status} />
      </Text>
    </VStack>
  );
};

export const ApplyCouponModal = ({
  isOpen,
  onClose,
  eventId,
  userId,
  orderId,
  registrationOptionId,
  userName,
}) => {
  const [couponCode, setCouponCode] = useState('');

  const [searchCoupon, { data: couponData, error: searchError, loading }] = useLazyQuery(SEARCH_COUPON, {
    fetchPolicy: 'network-only',
  });

  const onSearch = (e) => {
    e.preventDefault();
    searchCoupon({
      variables: {
        eventId,
        code: couponCode,
      },
    });
  };

  const toast = useToast();

  const [markAsPaid, { loading: markAsPaidLoading }] = useMarkAsPaidMutation(onClose);
  const [applyCoupon, { loading: applyCouponLoading }] = useMutation(APPLY_COUPON);
  const onConfirm = () => {
    applyCoupon({
      variables: {
        input: {
          orderId,
          registrationOptionId,
          couponCode,
        },
      },
    }).then((r) => {
      toast({
        title: `Successfully applied coupon ${couponCode} to ${userName}`,
        status: 'success',
        isClosable: true,
        position: 'top',
      });
      if (r.data.applyCoupon.order.amount <= 0) {
        markAsPaid({
          eventId,
          userId,
          userName,
        });
      } else {
        onClose();
      }
    }).catch((e) => {
      toast({
        title: e.graphQLErrors?.[0]?.message ?? e.message,
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Apply Coupon</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack>
            {searchError && (
              <Alert colorScheme="red">{searchError.graphQLErrors?.[0]?.message ?? searchError.message}</Alert>
            )}
            <form onSubmit={onSearch}>
              <HStack>
                <Input
                  type="text"
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                />
                <Button variant="outline" type="submit" isLoading={loading}>
                  Look Up
                </Button>
              </HStack>
            </form>
            {couponData && (
              <CouponInfo coupon={couponData.searchCouponInEvent} />
            )}
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onConfirm} isLoading={applyCouponLoading || markAsPaidLoading} disabled={!couponData}>
            Apply Coupon
          </Button>
          <Button colorScheme="gray" variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

ApplyCouponModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  orderId: PropTypes.number.isRequired,
  registrationOptionId: PropTypes.number.isRequired,
  userName: PropTypes.string.isRequired,
};
