import React, { useEffect, useState, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import { isEmpty, getOr } from 'lodash/fp';
import { UPDATE_SCHOOL, APPROVE_DENY_SCHOOL } from '../../GraphQL/Mutations';
import {
  FETCH_REGION,
  FETCH_SCHOOL_DETAIL,
  FETCH_REGION_BY_TENANT,
  FETCH_UNAPPROVE_SCHOOLS_BY_EVENT,
} from '../../GraphQL/Queries';

import { EVENT_TITLE } from '../../Constants/AppConstants';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useUpdateUnapprovedSchoolsCache from '../../Hooks/useUpdateUnapprovedSchoolsCache';
import useFormParams from '../../Hooks/useFormParams';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import GenericAlert from '../../Components/GenericAlert';
import useCurrentUser from '../../Hooks/userCurrentUser';
import useFilterArray from '../../Hooks/useFilterArray';
import Loader from '../../Components/Loader';
import Form from './Form';
import DenyForm from './DenyForm';

const FETCH_EVENT_HEADER = gql`
  query fetchEventDetail($id: Int!) {
    fetchEventDetail(id: $id) {
      id
      title
      label
    }
  }
`;


const SchoolEdit = () => {
  const {
    currentUser,
  } = useCurrentUser(true);
  const [regionLength, setRegionLength] = useState(0);
  const [regions, setRegions] = useState([]);
  const [school, setSchool] = useState({});
  const [regionIds, setRegionIds] = useState([]);
  const history = useHistory();
  const { id } = useParams();
  const { eventId } = useParams();
  const filterArray = useFilterArray();
  const checkPermission = useUserPermissionChecker(currentUser);
  const updateCache = useUpdateUnapprovedSchoolsCache(eventId);
  const { params, onChange, setParams } = useFormParams({
    name: '',
    address1: '',
    address2: '',
    website: '',
    country: '',
    stateCode: '',
    postalCode: '',
    phone: '',
    city: '',
    denyComment: '',
  });

  const { data } = useQuery(FETCH_EVENT_HEADER, {
    variables: {
      eventId,
    },
    skip: !eventId,
  });

  useHeaderComponentsMutation({
    backLink: eventId ? `/event/${eventId}/teams/${id}` : '/schools',
    title: 'EDIT SCHOOL',
    components: (eventId ? [{ key: EVENT_TITLE, value: data?.fetchEvent?.title }] : undefined),
  });
  const extractError = useGraphQLErrorExtractor();

  const [fetchUnapproveSchool, { data: dataUnapproveSchools }] = useLazyQuery(
    FETCH_UNAPPROVE_SCHOOLS_BY_EVENT,
  );

  const filteredUnapproveSchools = useMemo(() => filterArray((getOr([], 'fetchUnapproveSchool', dataUnapproveSchools)), 'status', 'pending'), [dataUnapproveSchools, filterArray]);

  const [approveDenySchool] = useMutation(
    APPROVE_DENY_SCHOOL,
    {
      onCompleted: () => {
        if (isEmpty(eventId)) {
          history.push('/schools');
        } else {
          updateCache();
          if (!isEmpty(filteredUnapproveSchools)) {
            history.push(`/event-manage/${eventId}/unapprove-schools`);
          } else {
            history.push(`/event-manage/${eventId}`);
          }
        }
      },
    },
  );

  const [updateSchool, { loading: updating, error: updateError }] = useMutation(
    UPDATE_SCHOOL,
    {
      onCompleted: () => {
        approveDenySchool({
          variables: {
            id: parseInt(id, 10),
            denyComment: '',
            status: checkPermission('Approve School') ? 2 : 1,
          },
        });
      },
    },
  );


  useEffect(() => {
    if (currentUser) {
      if (checkPermission('Update School') === undefined) {
        history.push('/schools');
      }
    }
  }, [currentUser, checkPermission, history]);


  const [fetchRegionsByTenant] = useLazyQuery(
    FETCH_REGION_BY_TENANT,
    {
      onCompleted: (data) => {
        setRegions(data.fetchRegionsByTenant);
      },
    },
  );


  const [fetchRegions] = useLazyQuery(
    FETCH_REGION, {
      onCompleted: (data) => {
        if (data.fetchRegions.length === 0) {
          fetchRegionsByTenant({
            variables: {
              tenantId: currentUser.tenantId,
            },
          });
        } else {
          setRegions(data.fetchRegions);
        }
      },
    },
  );

  useEffect(() => {
    if (currentUser) {
      fetchRegions({
        variables: {
          userId: parseInt(currentUser.id, 10),
        },
      });
    }
  }, [currentUser, fetchRegions]);

  useEffect(() => {
    if (currentUser && eventId) {
      fetchUnapproveSchool({
        variables: {
          eventId: parseInt(eventId, 10),
        },
      });
    }
  }, [currentUser, fetchUnapproveSchool, eventId]);

  const { loading, error } = useQuery(
    FETCH_SCHOOL_DETAIL,
    {
      onCompleted: (dataSchools) => {
        setSchool(dataSchools.fetchSchoolDetail);
        setParams(dataSchools.fetchSchoolDetail);
        setRegionLength(dataSchools.fetchSchoolDetail.regionIds.length);
        setRegionIds(dataSchools.fetchSchoolDetail.regionIds);
      },
      variables: { id: parseInt(id, 10) },
    },
  );

  const onSubmit = (e) => {
    const regionsArray = [];
    e.preventDefault();
    Object.keys(params).map((param, index) => (param.includes('regionUniqueId_')
      ? regionsArray.push(parseInt(Object.values(params)[index], 10))
      : null
    ));
    let uniqueRegionIds = regionsArray.concat(regionIds);
    uniqueRegionIds = uniqueRegionIds.filter((item, pos) => uniqueRegionIds.indexOf(item) === pos);
    updateSchool({
      variables: {
        ...params,
        status: checkPermission('Approve School') ? 2 : 1,
        tenantId: currentUser.tenantId,
        regionIds: uniqueRegionIds,
      },
    });
  };

  const onSubmitCancel = (e) => {
    e.preventDefault();
    approveDenySchool({
      variables: {
        id: parseInt(id, 10),
        denyComment: params.denyComment,
        status: 3,
      },
    });
  };


  const onRemove = (returnedRegionIds) => {
    setRegionIds(returnedRegionIds);
  };

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }

  return (
    <>
      <div className="row align-items-center">
        <div className="col-12 mx-auto px-4">
          {updateError ? <GenericAlert>{extractError(updateError)}</GenericAlert> : null}
          <Form
            className="pt-3"
            setParams={setParams}
            onSubmit={onSubmit}
            onChange={onChange}
            params={params}
            loading={updating}
            data={regions}
            regionLength={regionLength}
            selectedRegions={regionIds}
            onRemove={onRemove}
            formType="edit"
            currentUser={currentUser}
            schoolObj={school}
            schoolsUsersCount={school.schoolsUsersCount}
          />
          {school.status !== 'active' && school.schoolsUsersCount === 0
            ? (
              <DenyForm
                className="pt-3"
                setParams={setParams}
                onSubmit={onSubmitCancel}
                onChange={onChange}
                params={params}
              />
            )
            : null}
        </div>
      </div>
      <hr />
    </>
  );
};
export default SchoolEdit;
