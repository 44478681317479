import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/react-hooks';

import {
  Button,
  Grid,
  GridItem,
  Image,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';

export const APPROVE_EVENT_USER = gql`
  mutation approveUserByCaptain($id: ID!) {
    approveUserByCaptain(input:{
      id: $id,
      status: 2
    }) {
    eventsUsers{
      id
      status
      schoolId
      fetchTeam {
        id
        code
        school {
          id
          name
        }
      }
      fetchSchool {
        id
        name
      }
      fetchSchoolUser {
        status
        fetchUserAttachment {
          id
          verificationFileUrl
        }
        fetchSchool {
          id
          name
        }
      }
      status
    }
  }
}
`;

export const DENY_EVENT_USER = gql`
  mutation denyEventUser($id: ID!, $comment: String!) {
    denyEventUser(input:{
      id: $id,
      status: 1,
      comment: $comment
    }) {
    eventsUsers{
      id
      status
      schoolId
      fetchTeam {
        id
        code
        school {
          id
          name
        }
      }
      fetchSchool {
        id
        name
      }
      fetchSchoolUser {
        status
        fetchUserAttachment {
          id
          verificationFileUrl
        }
        fetchSchool {
          id
          name
        }
      }
      status
    }
  }
}
`;

export const ApproveRegModal = ({
  isOpen,
  onClose,
  eventId,
  user,
  eventUser,
}) => {
  const toast = useToast();

  const [denyMessageShown, setDenyMessageShown] = useState(false);
  const [comment, setComment] = useState();
  const [approveEventUser, { loading: approveLoading }] = useMutation(APPROVE_EVENT_USER, {
    variables: {
      id: eventUser.id,
    },
    onCompleted: () => {
      toast({
        title: `Approved ${user.firstname} ${user.lastname}'s registration in ${eventUser.fetchSchoolUser.fetchSchool.name}`,
        status: 'success',
        isClosable: true,
        position: 'top',
      });
      onClose();
    },
  });

  const [denyEventUser, { loading: denyLoading }] = useMutation(DENY_EVENT_USER, {
    variables: {
      id: eventUser.id,
      comment,
    },
    onCompleted: () => {
      toast({
        title: `Denied ${user.firstname} ${user.lastname}'s registration`,
        status: 'success',
        isClosable: true,
        position: 'top',
      });
      onClose();
    },
  });

  let { code } = user;
  if (eventUser.fetchTeam) {
    code = `${eventUser.fetchTeam.code}-${user.code}`;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Approve/Deny User</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Grid templateColumns="1fr 3fr" gap={4}>
            <GridItem colSpan={2} justifySelf="center">
              <Image src={user.profileImageUrl} maxHeight="64px" maxWidth="64px" />
            </GridItem>

            <GridItem>Name:</GridItem>
            <GridItem>
              {user.firstname}
              {' '}
              {user.lastname}
            </GridItem>

            <GridItem>Code:</GridItem>
            <GridItem>{code}</GridItem>

            <GridItem>Gender:</GridItem>
            <GridItem>{user.gender}</GridItem>

            <GridItem>Phone:</GridItem>
            <GridItem>{user.phone}</GridItem>

            <GridItem>Email:</GridItem>
            <GridItem>{user.email}</GridItem>

            <GridItem>School:</GridItem>
            <GridItem>{eventUser.fetchSchoolUser?.fetchSchool.name ?? '(none)'}</GridItem>

            {eventUser.fetchSchoolUser?.fetchUserAttachment.map((userAttachment) => (
              <GridItem colSpan={2} justifySelf="center">
                <Link href={userAttachment.verificationFileUrl} isExternal>
                  <figure>
                    <Image src={userAttachment.verificationFileUrl} maxWidth="100%" />
                    <Text align="center" as="figcaption" fontSize="s">
                      <em>
                        (click to open file in a new tab)
                      </em>
                    </Text>
                  </figure>
                </Link>
              </GridItem>
            ))}

            {denyMessageShown && (
              <>
                <GridItem>Deny comment:</GridItem>
                <GridItem>
                  <Input type="text" value={comment} onChange={(e) => setComment(e.target.value)} />
                </GridItem>
              </>
            )}

          </Grid>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={approveEventUser} isLoading={approveLoading} isDisabled={approveLoading || denyLoading}>
            Approve
          </Button>
          <Button
            colorScheme="red"
            mr={3}
            onClick={() => {
              if (denyMessageShown) {
                denyEventUser();
              } else {
                setDenyMessageShown(true);
              }
            }}
            isLoading={denyLoading}
            isDisabled={approveLoading || denyLoading || (denyMessageShown && !comment)}
          >
            {denyMessageShown ? 'Confirm Deny' : 'Deny'}
          </Button>
          <Button colorScheme="gray" variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

ApproveRegModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  userName: PropTypes.string.isRequired,
};
