import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  Select,
  Spinner,
  VStack,
  useToast,
} from '@chakra-ui/react';

const EVENT_DETAIL = gql`
  query fetchEventDetail($eventId: Int!) {
    fetchEventDetail(id: $eventId) {
      id
      title
      fetchRegistrationOptions {
        id
        title
        fetchRole { 
          id
          title
        }
        fee
        lateFee
        finalFee
        earlyDeadline
        finalDeadline
      }
    }
  }
`;

const CHANGE_REG_OPTION = gql`
  mutation changeRegistrationOptionInEvent($input: ChangeRegistrationOptionInEventInput!) {
    changeRegistrationOptionInEvent(input: $input) {
      warnings
      eventUser {
        id
        fetchRole {
          id
          title
        }
        registrationOptionId
        fetchOrder {
          id
          status
          amount
        }
      }
    }
  }
`;

export const ChangeRegOptionModal = ({
  isOpen,
  onClose,
  eventId,
  eventUserId,
  currentRegistrationOptionId,
  userName,
}) => {
  const toast = useToast();
  const [selectedRegistrationOption, setSelectedRegistrationOption] = useState();

  const { data: eventData, loading } = useQuery(EVENT_DETAIL, {
    variables: {
      eventId,
    },
  });
  const event = eventData?.fetchEventDetail;
  const registrationOptionsById = event?.fetchRegistrationOptions.reduce((acc, r) => ({
    ...acc,
    [r.id]: r,
  }), {}) ?? {};

  const onSelect = (e) => {
    const rId = e.target.value;
    setSelectedRegistrationOption(registrationOptionsById[rId]);
  };

  const [changeRegOption, { loading: changeRegOptionLoading }] = useMutation(CHANGE_REG_OPTION);
  const onConfirm = () => {
    changeRegOption({
      variables: {
        input: {
          eventUserId,
          registrationOptionId: selectedRegistrationOption.id,
        },
      },
    }).then((res) => {
      toast({
        title: `Successfully changed ${userName} to ${selectedRegistrationOption.title}`,
        status: 'success',
        isClosable: true,
        position: 'top',
      });

      const { warnings } = res.data.changeRegistrationOptionInEvent;
      if (warnings.length > 0) {
        toast({
          title: warnings.join('. '),
          status: 'warning',
          isClosable: true,
          position: 'top',
        });
      }

      onClose();
    }).catch((e) => {
      toast({
        title: e.graphQLErrors?.[0]?.message ?? e.message,
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Change Registration</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack>
            {loading ? (
              <Spinner />
            ) : (
              <Select onChange={onSelect}>
                {event.fetchRegistrationOptions.map((ro) => (
                  <option
                    value={ro.id}
                    selected={ro.id === selectedRegistrationOption?.id
                    || (!selectedRegistrationOption && Number.parseInt(ro.id, 10) === currentRegistrationOptionId)}
                  >
                    {ro.title}
                  </option>
                ))}
              </Select>
            )}
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onConfirm} isLoading={changeRegOptionLoading} disabled={!selectedRegistrationOption}>
            Change Registration Option
          </Button>
          <Button colorScheme="gray" variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

ChangeRegOptionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
  eventUserId: PropTypes.number.isRequired,
  currentRegistrationOptionId: PropTypes.number.isRequired,
  userName: PropTypes.string.isRequired,
};
