import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  HStack,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react';

import { Provider } from '../../Chakra';

const FIXED_AMOUNTS = [0, 10, 25, 50];
export const CartDonationSection = ({ donationAmount, setDonationAmount }) => {
  const isCustomAmount = !FIXED_AMOUNTS.includes(donationAmount);
  const [showCustomAmount, setShowCustomAmount] = useState(isCustomAmount);

  // i think this component doesn't get unmounted properly? maybe?
  // anyways if we leave and come back, the custom amount input isn't visible
  // this effect is used to kick it to show up
  useEffect(() => {
    if (isCustomAmount) {
      setShowCustomAmount(true);
    }
  }, [donationAmount, isCustomAmount]);

  return (
    <Provider>
      <VStack alignItems="start" margin={2}>
        <Text>
          Support us with a small donation!
        </Text>
        <HStack>
          {FIXED_AMOUNTS.map((amount) => (
            <Button
              colorScheme="blue"
              variant={donationAmount === amount ? 'solid' : 'outline'}
              onClick={() => {
                setShowCustomAmount(false);
                setDonationAmount(amount);
              }}
            >
              {`$${amount}`}
            </Button>
          ))}
          <Button
            colorScheme="blue"
            variant={isCustomAmount || showCustomAmount ? 'solid' : 'outline'}
            onClick={() => {
              if (!showCustomAmount) {
                setShowCustomAmount(true);
                setDonationAmount();
              } else {
                setShowCustomAmount(false);
                setDonationAmount(0);
              }
            }}
          >
            Other
          </Button>
        </HStack>
        {showCustomAmount && (
          <Input
            value={Number.isNaN(donationAmount) ? '' : donationAmount}
            onChange={(e) => {
              setDonationAmount(e.target.value);
            }}
            placeholder="Custom Amount"
          />
        )}
      </VStack>
    </Provider>
  );
};
CartDonationSection.propTypes = {
  donationAmount: PropTypes.number.isRequired,
  setDonationAmount: PropTypes.func.isRequired,
};
