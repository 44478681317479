import React, { useState, useEffect } from 'react';
import {
  useParams,
} from 'react-router-dom';
import {
  useLazyQuery,
} from '@apollo/react-hooks';
import { isEmpty, getOr } from 'lodash/fp';
import useCurrentUser from '../../Hooks/userCurrentUser';
import {
  MANAGE_MY_COMPETITIONS,
  FETCH_ORDERS_BY_EVENT,
  FETCH_EVENT_DETAIL,
} from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import BasicEventCompetitions from './BasicEventCompetitions';
import { EVENT_TITLE } from '../../Constants/AppConstants';

const EditMyCompetitions = () => {
  const {
    currentUser,
  } = useCurrentUser(true);
  const { id } = useParams();
  const [myCompetitions, setMyCompetitions] = useState([]);
  const [manageMyCompetitions, { data }] = useLazyQuery(
    MANAGE_MY_COMPETITIONS, {
      onCompleted: () => {
        setMyCompetitions(data.manageMyCompetitions);
      },
    },
  );

  const [fetchOrdersByEvent, { data: dataOrder }] = useLazyQuery(
    FETCH_ORDERS_BY_EVENT, {
    },
  );

  const order = getOr({}, 'fetchOrdersByEvent[0]', dataOrder);

  const [fetchEventDetail, { data: dataEvent }] = useLazyQuery(FETCH_EVENT_DETAIL);
  const event = getOr({}, 'fetchEventDetail', dataEvent);

  useHeaderComponentsMutation({
    title: 'My competitions',
    backLink: `/events/${id}/my-competitions`,
    components: [{ key: EVENT_TITLE, value: event.title }],
  });

  useEffect(() => {
    if (!isEmpty(currentUser.fetchSchools)) {
      manageMyCompetitions({
        variables: {
          eventId: parseInt(id, 10),
          userId: parseInt(currentUser.id, 10),
        },
      });
      fetchOrdersByEvent({
        variables: {
          userId: parseInt(currentUser.id, 10),
          eventId: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, manageMyCompetitions, id, fetchOrdersByEvent]);

  useEffect(() => {
    if (currentUser && id) {
      fetchEventDetail({
        variables: {
          id: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, fetchEventDetail, id]);

  return (
    <>
      {myCompetitions.length === 0
        ? (
          null
        )
        : (
          <div className="container px-0 pt-3 ml-0">
            <div className="row">
              <div className="col-md-12 col-12 font-weight-bold">
                Your  Competitions
              </div>
            </div>
          </div>
        )}
      <BasicEventCompetitions
        order={order}
        addNewText="SAVE"
        addNewLink={`/events/${id}/my-competitions`}
        eventAllowsMultipleSports={event?.allowsMultipleSports}
      />
    </>
  );
};

export default EditMyCompetitions;
