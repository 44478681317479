import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { UPDATE_COMPETITION } from '../../GraphQL/Mutations';
import { FETCH_COMPETITION_DETAIL } from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useFormParams from '../../Hooks/useFormParams';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import GenericAlert from '../../Components/GenericAlert';
import useCurrentUser from '../../Hooks/userCurrentUser';
import Loader from '../../Components/Loader';
import Form from './Form';


const CompetitionEdit = () => {
  const { currentUser } = useCurrentUser(true);
  const history = useHistory();
  const { id } = useParams();
  const [categoryId, setCategoryId] = useState('');
  const { params, onChange, setParams } = useFormParams({
    title: '',
    matchup: false,
    group: false,
    genderEnable: false,
    onlineSubmission: false,
    minTeam: 0,
    maxTeam: 0,
    maxSubmission: 0,
    description: '',
    requiresAdditionalCompetition: false,
  });

  const { loading, error } = useQuery(
    FETCH_COMPETITION_DETAIL,
    {
      onCompleted: (data) => {
        setParams({
          ...data.fetchCompetitionDetail,
          ballotId: data.fetchCompetitionDetail.fetchBallot?.id,
        });

        setCategoryId(data.fetchCompetitionDetail.categoryId);
      },
      variables: { id: parseInt(id, 10) },
    },
  );

  useHeaderComponentsMutation({ title: 'COMPETITIONS' });
  useHeaderComponentsMutation({
    backLink: `/competitions/${parseInt(categoryId, 10)}`,
    title: 'COMPETITIONS',
  });
  const extractError = useGraphQLErrorExtractor();
  const [updateCompetition, { loading: updating, error: updateError }] = useMutation(
    UPDATE_COMPETITION,
    {
      onCompleted: () => {
        history.push(`/competitions/${parseInt(categoryId, 10)}`);
      },
    },
  );

  const onSubmit = (e) => {
    e.preventDefault();
    params.minTeam = parseInt(params.minTeam, 10);
    params.maxTeam = parseInt(params.maxTeam, 10);
    params.maxSubmission = parseInt(params.maxSubmission, 10);
    params.ballotId = parseInt(params.ballotId, 10);
    updateCompetition({
      variables: params,
    });
  };
  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }

  return (
    <>
      <div className="row align-items-center">
        <div className="col-12 mx-auto px-4">
          {updateError ? <GenericAlert>{extractError(updateError)}</GenericAlert> : null}
          <Form
            setParams={setParams}
            onSubmit={onSubmit}
            onChange={onChange}
            params={params}
            loading={updating}
            isEdit
            formType="edit"
            currentUser={currentUser}
          />
        </div>
      </div>
      <hr />
    </>
  );
};
export default CompetitionEdit;
