import React, { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import {
  isEmpty, last, get, first,
} from 'lodash/fp';
import { FETCH_SCHOOLS, SEARCH_SCHOOLS } from '../../GraphQL/Queries';
import { UPDATE_SCHOOL_VERIFICATION, CHANGE_SCHOOL } from '../../GraphQL/Mutations';
import useCurrentUser from '../../Hooks/userCurrentUser';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import ImageUpload from '../ImageUpload/New';
import SearchDialog from '../../Components/SearchDialog/Index';
import SEARCH from '../../Images/search.png';
import {
  EventImageUpload, SelectedSchool, LinkButton,
} from '../../Components/Auth/Layout';
import useInvitationCode from '../../Hooks/useInvitationCode';
import useSortArrayByAlphabets from '../../Hooks/useSortArrayByAlphabets';
import useUpdateUserDetailCache from '../../Hooks/useUpdateUserDetailCache';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import Loader from '../../Components/Loader';
import GenericAlert from '../../Components/GenericAlert';
import Permissions from '../../Constants/PermissionConstrants';
import BackLink from '../../Components/BackLink';


const SchoolVerification = () => {
  const {
    currentUser,
  } = useCurrentUser(true);

  useHeaderComponentsMutation({
    title: 'SCHOOL VERIFICATION',
    backLink: '/',
  });
  const history = useHistory();
  const sortedArray = useSortArrayByAlphabets();
  const checkPermission = useUserPermissionChecker(currentUser);
  const updateCache = useUpdateUserDetailCache(get('id', currentUser));
  const extractError = useGraphQLErrorExtractor();
  const [error, setError] = useState();
  const { getInvitationCode, getInvitedEventId } = useInvitationCode();
  const [searchStringLength, setSearchStringLength] = useState(0);
  const [filteredSchools, setFilteredSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState({ id: '', name: '' });
  const [avatarPreview, setAvatarPreview] = useState('');

  const joinMultipleSchools = useMemo(() => (
    checkPermission(Permissions.CAN_JOIN_MULTIPLE_SCHOOL)),
  [checkPermission]);

  const [fetchSchools] = useLazyQuery(
    FETCH_SCHOOLS, {
      onCompleted: (data) => {
        setFilteredSchools(sortedArray(data.fetchSchools, 'name'));
      },
    },
  );
  const [schoolUserAttachment, { loading: addSchoolLoading }] = useMutation(
    UPDATE_SCHOOL_VERIFICATION,
    {
      onCompleted: (data) => {
        const schoolUser = last(data.schoolUserAttachment.schoolsUsers);
        setAvatarPreview(last(schoolUser.fetchUserAttachment).verificationFileUrl);
        if (getInvitationCode || getInvitedEventId) {
          currentUser.fetchSchools = schoolUser.fetchUser.fetchSchools;
        }
      },
    },
  );
  const [changeUserSchool, { loading: changeSchoolLoading }] = useMutation(
    CHANGE_SCHOOL,
    {
      onCompleted: (data) => {
        updateCache(data.changeUserSchool.schoolsUsers);
        const schoolUser = last(data.changeUserSchool.schoolsUsers);
        setAvatarPreview(last(schoolUser.fetchUserAttachment).verificationFileUrl);
        if (getInvitationCode || getInvitedEventId) {
          currentUser.fetchSchools = schoolUser.fetchUser.fetchSchools;
        }
      },
      onError: (err) => {
        setError(extractError(err));
      },
    },
  );
  const [clickStatus, setClickStatus] = useState(false);

  const onImageUpload = (image) => {
    if (!isEmpty(get('fetchSchoolsUsers', currentUser))) {
      return changeUserSchool({
        variables: {
          verificationFile: image,
          currentSchoolId: parseInt(get('fetchSchool', first(currentUser.fetchSchoolsUsers)).id, 10),
          userId: parseInt(currentUser.id, 10),
          newSchoolId: parseInt(selectedSchool.id, 10),
        },
      });
    }

    return schoolUserAttachment({
      variables: {
        verificationFile: image,
        schoolId: parseInt(selectedSchool.id, 10),
        userId: parseInt(currentUser.id, 10),
      },
    });
  };

  useEffect(() => {
    if (currentUser) {
      fetchSchools({
        variables: {
          tenantId: currentUser.tenantId,
        },
      });
    }
  }, [currentUser, fetchSchools]);

  const [schoolsSearch] = useLazyQuery(
    SEARCH_SCHOOLS,
    {
      onCompleted: (data) => {
        setFilteredSchools(sortedArray(data.searchSchools, 'name'));
      },
    },
  );

  const searchSchools = (e) => {
    setSearchStringLength(e.target.value.length);
    schoolsSearch({
      variables: {
        query: e.target.value,
        userId: parseInt(currentUser.id, 10),
      },
    });
  };

  const getSelectedSchool = (e) => {
    if (e.target.id !== '') {
      setClickStatus(false);
      setSearchStringLength(0);
      setSelectedSchool({ id: parseInt(e.target.id, 10), name: e.target.innerText });
    }
  };

  useEffect(() => {
    if (!isEmpty(get('fetchSchoolsUsers', currentUser))) {
      setClickStatus(false);
      setSearchStringLength(0);
      setSelectedSchool({
        id: parseInt(get('fetchSchool', first(currentUser.fetchSchoolsUsers)).id, 10),
        name: get('fetchSchool', first(currentUser.fetchSchoolsUsers)).name,
      });
    }
  }, [currentUser, joinMultipleSchools]);

  useEffect(() => {
    if (isEmpty(get('fetchSchoolsUsers', currentUser)) && !isEmpty(get('fetchSchools', currentUser))) {
      setClickStatus(false);
      setSearchStringLength(0);
      setSelectedSchool({
        id: parseInt(get('id', first(currentUser.fetchSchools)), 10),
        name: get('name', first(currentUser.fetchSchools)),
      });
    }
  }, [currentUser]);

  const updateSchool = () => {
    if (getInvitationCode || getInvitedEventId) {
      history.push(`/join-event/${getInvitedEventId}?invitation_code=${getInvitationCode}`);
    } else {
      history.push('/');
    }
  };


  if (changeSchoolLoading || addSchoolLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="row">
        <div className="col-12 mx-auto px-4">
          {error ? <GenericAlert>{error}</GenericAlert> : null}
        </div>
        <div className="col-12 mx-auto px-4">
          <p className="pt-3" style={{ fontWeight: '600' }}>Default School</p>
          <SearchDialog
            placeholder="Search"
            onChange={(e) => searchSchools(e)}
            data={filteredSchools}
            stringlength={searchStringLength}
            displayattribute="name"
            imageattatched={SEARCH}
            emptySearch={clickStatus}
            onClick={(e) => getSelectedSchool(e)}
            addSchoolSearch
            checkPermission
          />
          <SelectedSchool>
            <h5>{selectedSchool.name}</h5>
          </SelectedSchool>
          {selectedSchool.id
            ? (
              <>
                <p>
                  Please provide proof of your affiliation with your selected high school.
                  Students may provide School ID.
                  Coaches and guests may provide any government-issued picture ID.
                </p>
                <p className="pt-3" style={{ fontWeight: '600' }}>Upload School Verification</p>
                <EventImageUpload>
                  <ImageUpload
                    onUpload={onImageUpload}
                    existingImageUrl={avatarPreview}
                    additionalText="Accepted file formats: .jpg, .png"
                  />
                </EventImageUpload>
              </>
            )
            : null}
          <div className="pt-3" />
        </div>
      </div>
      {avatarPreview
        ? <LinkButton loading={changeSchoolLoading} onClick={() => updateSchool()} backgroundcolor="#F4AB37" fontcolor="#FFF" border="none">CONTINUE</LinkButton>
        : null}
      <div className="pt-3" />
      {checkPermission(Permissions.CAN_JOIN_SCHOOL_OPTIONAL)
        ? (
          <BackLink to="/" imageNotRequired>
              Skip For Now
          </BackLink>
        )
        : null}
    </>
  );
};

export default SchoolVerification;
