/* eslint-disable no-plusplus */
import React from 'react';
import { useParams } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';
import ReactExport from 'react-export-excel';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  ScoreButton,
} from '../../Components/Auth/Layout';
import {
  FETCH_COMPS_SPREADSHEET_STUDENTS,
} from '../../GraphQL/Queries';
import useCurrentUser from '../../Hooks/userCurrentUser';
import Loader from '../../Components/Loader';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;

const FETCH_COALITIONS = gql`
  query fetchCoalitions($eventId: Int!){
    fetchCoalitions(eventId: $eventId, ) {
      id
      eventCompetitionId
      code
      fetchCoalitionsSchools {
        fetchCoalitionMembers {
          id
          user {
            id
            code
          }
        }
      }
    }
  }
`;

const useCoalitionData = ({ eventId, enabled }) => {
  const { data, loading } = useQuery(
    FETCH_COALITIONS, {
      variables: {
        eventId,
      },
      skip: !enabled,
    },
  );

  if (loading) {
    return { loading };
  }

  const coalitions = data.fetchCoalitions;
  const coalitionsByUser = {};
  coalitions.forEach((coalition) => {
    coalition.fetchCoalitionsSchools.forEach((cs) => {
      cs.fetchCoalitionMembers.forEach((cm) => {
        if (!coalitionsByUser[cm.user.code]) {
          coalitionsByUser[cm.user.code] = {};
        }
        coalitionsByUser[cm.user.code][coalition.eventCompetitionId] = coalition.code;
      });
    });
  });

  return {
    coalitionsByUser,
    loading: false,
  };
};

const combineCompsAndCoalitions = ({
  compsByCategory,
  coalitions,
}) => {
  if (!compsByCategory) {
    return {};
  }

  return Object.fromEntries(
    Object.entries(compsByCategory).map(([categoryName, comps]) => {
      const mappedComps = comps.map((comp) => {
        if (coalitions?.[comp.eventCompetitionId]) {
          return {
            ...comp,
            coalition: coalitions[comp.eventCompetitionId],
          };
        }
        return comp;
      });

      return [categoryName, mappedComps];
    }),
  );
};

const buildCompsReportDataset = ({
  competitorRegData,
  competitorCompetitions,
  coalitionsByUser,
}) => competitorRegData.map((eventUser) => {
  const competitor = eventUser.fetchUser;
  const mistCode = eventUser.fetchTeam ? (`${eventUser.fetchTeam.code}-${competitor.code}`) : competitor.code;

  const res = {
    firstName: competitor.firstname,
    lastName: competitor.lastname,
    email: competitor.email,
    mistCode,
    schoolName: eventUser.fetchSchool?.name,
    region: competitor.defaultRegion?.name,
    registrationOption: eventUser.fetchRegistrationOption?.title ?? '',
    competitions: combineCompsAndCoalitions({
      compsByCategory: competitorCompetitions[competitor.code],
      coalitions: coalitionsByUser[competitor.code],
    }),
  };

  return res;
});

const buildCategoryTitle = (categoryTitle, idx, maxCompsInCategory) => {
  if (maxCompsInCategory > 1) {
    return `${categoryTitle} ${idx}`;
  }
  return categoryTitle;
};

const buildCompetitionTitle = ({ title, status }) => {
  if (!title) {
    return '';
  }
  if (status === 'waitlist') {
    return `${title} (waitlisted)`;
  }
  return title;
};

const buildColumns = ({
  dataset,
  categories,
  includeRegionColumn,
}) => {
  const columns = [
    <ExcelColumn label="Registration Option" value="registrationOption" />,
    <ExcelColumn label="MIST ID" value="mistCode" />,
    <ExcelColumn label="First Name" value="firstName" />,
    <ExcelColumn label="Last Name" value="lastName" />,
    <ExcelColumn label="Email" value="email" />,
    <ExcelColumn label="School" value="schoolName" />,
    includeRegionColumn ? <ExcelColumn label="Region" value="region" /> : null,
  ];

  categories.forEach((category) => {
    let hasCoalitions = false;
    let numColumns = 1;
    dataset.forEach((row) => {
      const comps = row.competitions[category.title] ?? [];
      numColumns = Math.max(comps.length, numColumns);
      hasCoalitions = hasCoalitions || comps.some((comp) => !!comp.coalition);
    });

    for (let i = 0; i < numColumns; i++) {
      const columnName = buildCategoryTitle(category.title, i + 1, numColumns);
      columns.push(
        <ExcelColumn
          label={columnName}
          value={(row) => buildCompetitionTitle(row.competitions[category.title]?.[i] ?? {})}
        />,
      );
      if (hasCoalitions) {
        columns.push(
          <ExcelColumn
            label={`${columnName} Coalition`}
            value={(row) => row.competitions[category.title]?.[i]?.coalition}
          />,
        );
      }
    }
  });

  return columns.filter((c) => c !== null);
};


const useCompetitorRegData = ({ eventId, enabled }) => {
  const { data: compsSpeadsheetStudents, loading } = useQuery(
    FETCH_COMPS_SPREADSHEET_STUDENTS, {
      variables: {
        eventId,
      },
      skip: !enabled,
    },
  );

  if (loading) {
    return { loading };
  }

  const { eventStudents } = compsSpeadsheetStudents;

  return {
    competitorRegData: eventStudents,
    loading: false,
  };
};

const getCompetitionsByUser = (categories) => {
  const competitorCompetitions = {};
  for (let j = 0; j < categories.length; j += 1) {
    const category = categories[j];
    for (let i = 0; i < category.eventCompetitions.length; i += 1) {
      const eventCompetition = category.eventCompetitions[i];
      for (let k = 0; k < eventCompetition.users.length; k += 1) {
        const eventCompetitionUser = eventCompetition.users[k];
        const categoryTitle = category.title;
        const competitionTitle = get('title', eventCompetition);
        const mistCode = get('code', eventCompetitionUser.user);

        if (!(mistCode in competitorCompetitions)) {
          competitorCompetitions[mistCode] = {};
        }
        if (!(categoryTitle in competitorCompetitions[mistCode])) {
          competitorCompetitions[mistCode][categoryTitle] = [];
        }

        competitorCompetitions[mistCode][categoryTitle].push({
          title: competitionTitle,
          eventCompetitionId: eventCompetition.id,
          status: eventCompetitionUser.member.status,
        });
      }
    }
  }

  return competitorCompetitions;
};

export const CompetitionReportExport = ({
  categories,
  filename,
  includeRegionColumn,
}) => {
  const {
    currentUser,
  } = useCurrentUser(true);
  const { id } = useParams();

  const competitorCompetitions = getCompetitionsByUser(categories);

  const { competitorRegData, loading: competitorsLoading } = useCompetitorRegData({
    eventId: parseInt(id, 10),
    enabled: !!currentUser,
  });

  const { coalitionsByUser, loading: coalitionsLoading } = useCoalitionData({
    eventId: parseInt(id, 10),
    enabled: !!currentUser,
  });

  if (competitorsLoading || coalitionsLoading) {
    return <Loader />;
  }

  const dataset = buildCompsReportDataset({
    competitorRegData,
    competitorCompetitions,
    coalitionsByUser,
  });

  const columns = buildColumns({
    dataset,
    categories,
    includeRegionColumn,
  });

  return (
    <ExcelFile
      filename={`${filename} (${moment().format('YYYY-MM-DD')})`}
      element={<ScoreButton>EXPORT COMPETITIONS REPORT</ScoreButton>}
    >
      <ExcelSheet
        data={dataset}
        name="competitions"
      >
        {columns}
      </ExcelSheet>
    </ExcelFile>
  );
};
CompetitionReportExport.propTypes = {
  categories: PropTypes.arrayOf({
    eventCompetitions: PropTypes.arrayOf({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      users: PropTypes.arrayOf({
        user: PropTypes.shape({
          code: PropTypes.string.isRequired,
        }).isRequired,
        member: PropTypes.shape({
          status: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  filename: PropTypes.string.isRequired,
  includeRegionColumn: PropTypes.bool.isRequired,
};
