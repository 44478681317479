import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { get, getOr, isEmpty } from 'lodash/fp';
import {
  FETCH_EVENT_COMPETITIONS_BY_GENDER,
  MANAGE_MY_COMPETITIONS,
} from '../../GraphQL/Queries';
import useUpdateManageMyCompetitionsCache from '../../Hooks/useUpdateManageMyCompetitionsCache';
import Loader from '../../Components/Loader';
import BasicCategory from './BasicCategory';
import { RedirectButton } from '../../Components/Form';
import { AlignCenter } from '../../Components/Auth/Layout';

const SPORTS_CATEGORY_ID = '6';

const SET_EVENT_COMPETITIONS = gql`
  mutation setEventCompetition($input: SetEventCompetitionInput!) {
    setEventCompetition(input: $input) {
      members {
        id
        fetchEventCompetition {
          id
          title
          status
        }
      }
    }
  }
`;

const buildCategoriesMap = (data, eventId) => {
  const availableCompetitions = data.fetchEventCompetitionsByGender.filter(
    (comp) => comp.eventId === eventId,
  );
  const categories = {
    // id to massive obj
  };
  availableCompetitions.forEach((c) => {
    const category = c.fetchCompetition.fetchCategory;
    if (!categories[category.id]) {
      categories[category.id] = {
        title: category.title,
        id: category.id,
        competitions: [],
      };
    }
    categories[category.id].competitions.push({
      title: c.title,
      id: c.id,
    });
  });
  const vals = Object.values(categories);
  vals.sort((c1, c2) => parseInt(c1.id, 10) - parseInt(c2.id, 10));
  return vals;
};

const initSelectedComps = (oldSelectedCompetitions, competitionsByCategory) => {
  const initialSelectedCompetitions = {};
  Object.keys(oldSelectedCompetitions).forEach((eventCompetitionId) => {
    const category = competitionsByCategory.find((cat) => cat.competitions.find(
      (comp) => comp.id === eventCompetitionId,
    ));
    if (category) {
      if (!initialSelectedCompetitions[category.id]) {
        initialSelectedCompetitions[category.id] = [];
      }
      initialSelectedCompetitions[category.id].push(eventCompetitionId);
    }
  });
  return initialSelectedCompetitions;
};

const BasicEventCompetitions = (props) => {
  const {
    order,
    addNewText,
    addNewLink,
    permissionName,
    eventAllowsMultipleSports,
  } = props;
  const eventId = parseInt(get('eventId', order), 10);
  const eventsUser = order.fetchEventsUser;
  const userId = getOr(null, 'userId', eventsUser);
  const history = useHistory();

  const updateCache = useUpdateManageMyCompetitionsCache(
    eventId, userId,
  );

  const [competitionsByCategory, setCompetitionsByCategory] = useState([]);
  // looks like
  // const competitionsByCategory = [
  //   {
  //       title: 'Knowledge and Quran',
  //       id: 1,
  //       competitions: [
  //           {
  //               id: '123',
  //               title: 'Quran Memorization Level 1'
  //           }
  //       ]
  //   },
  //   {
  //       title: 'Arts',
  //       id: 2,
  //       competitions: [
  //           {
  //               id: '456',
  //               title: '2D Art'
  //           },
  //           {
  //               id: '457',
  //               title: '3D Art'
  //           }
  //       ]
  //   }
  // ];
  // "competition" objects are actually event_competitions!

  const [oldSelectedCompetitions, setOldSelectedCompetitions] = useState({});
  // keys are event_competition_id, value are objects with member_id
  // looks like
  // const oldSelectedCompetitions = {
  //   '952': {
  //     'memberId': '412',
  //   },
  //   '999': {
  //     'memberId': '413',
  //   }
  // }

  const [selectedCompetitions, setSelectedCompetitions] = useState({});
  // keys are category id, values are list of event_competition_id
  // looks like
  // const selectedCompetitions = {
  //   '1': ['123'],
  //   '2': ['456']
  // }

  useEffect(() => {
    setSelectedCompetitions(initSelectedComps(oldSelectedCompetitions, competitionsByCategory));
  }, [oldSelectedCompetitions, competitionsByCategory]);


  const [setEventCompetitions, { loading }] = useMutation(SET_EVENT_COMPETITIONS, {
    onCompleted: (data) => {
      updateCache(data.setEventCompetition.members, 'add');
    },
  });


  const onSave = () => {
    const selectedCompetitionIds = Object.values(selectedCompetitions)
      .flatMap((arr) => arr)
      .filter((ecId) => ecId !== '-1');

    setEventCompetitions({
      variables: {
        input: {
          eventsUserId: eventsUser.id,
          eventCompetitionIds: selectedCompetitionIds,
        },
      },
    }).then(() => history.push(addNewLink));
  };

  const updateCompetition = (categoryId, newSelectedCompetitions) => {
    setSelectedCompetitions({
      ...selectedCompetitions,
      [categoryId]: newSelectedCompetitions,
    });
  };

  const { loading: allEventCompsLoading } = useQuery(
    FETCH_EVENT_COMPETITIONS_BY_GENDER,
    {
      variables: {
        eventId: parseInt(eventId, 10),
        gender: eventsUser?.fetchUser.gender,
      },
      onCompleted: (data) => {
        const compsByCategory = buildCategoriesMap(data, eventId);
        setCompetitionsByCategory(compsByCategory);
      },
      skip: isEmpty(order) || !eventsUser,
    },
  );

  const { loading: myCompsLoading } = useQuery(
    MANAGE_MY_COMPETITIONS,
    {
      variables: {
        eventId,
        userId,
      },
      onCompleted: (data) => {
        const selectedComps = {};
        data.manageMyCompetitions.forEach((member) => {
          selectedComps[member.eventCompetitionId] = {
            memberId: member.id,
            competitionId: member.fetchEventCompetition.competitionId,
          };
        });
        setOldSelectedCompetitions(selectedComps);
      },
      skip: !eventId || !userId,
    },
  );

  if (myCompsLoading || allEventCompsLoading) {
    return <Loader />;
  }

  return (
    <div>
      {competitionsByCategory.map((cat) => (
        <div key={cat.id}>
          <BasicCategory
            key={cat.id}
            id={cat.id}
            title={cat.title}
            competitions={cat.competitions}
            onChange={updateCompetition}
            initialSelectedComps={selectedCompetitions[cat.id]}
            allowMultiple={eventAllowsMultipleSports && cat.id === SPORTS_CATEGORY_ID}
          />
        </div>
      ))}
      <AlignCenter>
        <RedirectButton
          backgroundcolor="#F4AB37"
          fontColor="#FFF"
          border="none"
          loading={loading}
          addNewText={addNewText}
          // addNewLink={addNewLink}
          permissionName={permissionName}
          onClick={onSave}
        />
      </AlignCenter>
    </div>
  );
};


BasicEventCompetitions.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
  addNewText: PropTypes.string.isRequired,
  addNewLink: PropTypes.string.isRequired,
  permissionName: PropTypes.string,
  eventAllowsMultipleSports: PropTypes.bool.isRequired,
};

BasicEventCompetitions.defaultProps = {
  permissionName: null,
};

export default BasicEventCompetitions;
